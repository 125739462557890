import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: `'DM Sans', sans-serif`,
    fontSize: "16px",
    fontWeight: "400",
    color: theme.common.neutral100,
  },
  iconBtn: {
    fontSize: "14px",
    alignItems: "center",
    maxWidth: "420px",
    "& button": {
      minWidth: "22px !important",
      minHeight: "22px !important",
      border: "1px solid transparent",
      background: "none",
      "& svg": {
        color: theme.common.black,
      },
      "&:hover": {
        background: "none",
      },
    },
  },
  inputBorder: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 52,
      border: `1px solid ${theme.common.lightGrayBorder}`,
      borderRadius: "44px",
      background:theme.common.lightGrey,
      [theme.breakpoints.up("sm")]:{
        marginBottom:0
      },
      '& .MuiOutlinedInput-input:-webkit-autofill' : {
        "box-shadow" : "0 0 0 1000px #ffffff inset !important",
        "-webkit-text-fill-color": "#000 !important"
      },
      '& .MuiOutlinedInput-input':{
        fontSize:18,
      }
    },
    [theme.breakpoints.down("450")]: {
      margin: "auto",
    },
  },
  sendBtn: {
    height: "48px",
    padding: "10px 16px",
    "& .MuiButton-label": {
      fontFamily: `'DM Sans', sans-serif`,
      fontSize: "14px",
      fontWeight: "400",
    },
  },
  signInBtn: {
    fontFamily: `'DM Sans', sans-serif`,
    fontWeight: "500",
    fontSize: "14px",
    color: theme.common.neutral100,
  },
  error: {
    color: "red",
    fontSize: "14px",
    margin: "4px 14px 0",
    position: "absolute",
    [theme.breakpoints.down("450")]: {
      fontSize: "12px",
    },
  },
  link: {
    color: theme.palette.primary.main
  },
  textBtn:{
    color: theme.palette.primary.extraDark,
    textDecoration:"underline",
    fontFamily:theme.typography.fontFamily.medium,
    fontWeight:500,
    fontSize:"16px",
    [theme.breakpoints.down("450")]: {
      fontSize: "14px",
    },
    background: "transparent",
    border: "none",
    cursor:"pointer"
  }
}));

export default useStyles;
