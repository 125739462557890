const initialState = {
  typingUsers: [],
};

const START_TYPING = "START_TYPING";
const STOP_TYPING = "STOP_TYPING";

export const typing = (state = initialState, action) => {
  switch (action.type) {
    case START_TYPING:
      return {
        ...state,
        typingUsers: state.typingUsers.includes(action.payload)
          ? state.typingUsers // If user already exists, return the same array
          : [...state.typingUsers, action.payload], // Otherwise, add the new user
      };

    case STOP_TYPING:
      return {
        ...state,
        typingUsers: state.typingUsers.filter(
          (user) => user !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default typing;
