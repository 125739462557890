import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerLinks: {
    fontSize: 16,
    color: theme.common.gary_200,
    fontFamily: theme.typography.fontFamily.medium,
  },
  hamburgerLinks: {
    fontSize: 18,
    display: "block",
    color: theme.common.neutral100,
    padding: theme.spacing(1.5, 0),
    fontFamily: theme.typography.fontFamily.medium,

    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "14px",
    },
  },
  hamburgerBtn: {
    "&.MuiButton-root": {
      minWidth: "50px"
    },
  },
  logo: {
    width: 150,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      paddingLeft: 0,
    },
  },
  btnGrid: {
    textAlign: "right",
  },
  headerMenu: {
    "& div:first-child": {
      marginRight: "0px !important",
      gap: "60px",
      [theme.breakpoints.down("sm")]: {
        gap: 20,
      },
    },
  },
  profileIcon: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  avatarBtn:{
    background:theme.common.grayDisabled,
    padding: '4px 8px',
    '& svg':{
      marginLeft:8,
      color: theme.common.gary_200,
      width: 24,
      height:24
    }
  },
  profileText:{
    fontFamily: theme.typography.fontFamily.medium,
    marginLeft:8,
    lineHeight:1,
    fontWeight:500
  },
  registerBtn: {
    fontSize: "16px",
    fontWeight: "500",
    color: theme.common.white,
    background: theme.palette.primary.extraDark,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight:26,
      padding:0
    },
    "&:hover": { color: theme.palette.primary.extraDark },
  },
  signInBtn: {
    height: "48px",
    color: theme.palette.primary.extraDark,
    fontSize: "16px",
    marginRight: "24px",
    textTransform:'unset !important',
    [theme.breakpoints.down("960")]: {
      fontSize: "12px",
      marginRight: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight:32,
      padding:0
    },
    " & .MuiButton-label": {
      fontFamily: theme.typography.fontFamily.bold,
    },
  },
  avtar: {
    fontFamily: `${theme.typography.fontFamily.medium} !important`,
    fontWeight:theme.typography.fontWeight.medium,
    fontSize: "16px !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px !important",
      height: "26px !important",
      width: "26px !important",
    },
    ".MuiAvatar-root" : {
      fontFamily: `${theme.typography.fontFamily.medium} !important`,
    }
  },
}));

export default useStyles;
