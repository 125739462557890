import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    alignItems: "center",
    "& .MuiAlert-message": {
      fontSize: "16px",
      width:"100%",
      [theme.breakpoints.down("450")]: {
        fontSize:14,
      },
    },
    "& .MuiIconButton-root": {
      minHeight: "24px",
      minWidth: "24px",
    },
    "& button": {
      minWidth: "22px !important",
      minHeight: "22px !important",
      border: "1px solid transparent",
      background: "none",
      "& svg": {
        color: theme.common.black,
      },
      "&:hover": {
        background: "none",
      },
    },
  },
  resendLink: {
    fontSize: "14px",
    fontWeight: "600",
    marginLeft: "3px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  inputBorder: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 52,
      border: `1px solid ${theme.common.lightGrayBorder}`,
      borderRadius: "44px",
      background:theme.common.lightGrey,
      [theme.breakpoints.up("sm")]:{
        marginBottom:0
      },
      '& .MuiOutlinedInput-input':{
        fontSize:18,
        [theme.breakpoints.down("450")]: {
          fontSize:14,
        },
      }
    },
    [theme.breakpoints.down("450")]: {
      margin: "auto",
    },
  },
  loginBtn: {
    height: "48px",
    padding: "10px 16px",
    "& .MuiButton-label": {
      fontFamily:theme.typography.fontFamily.medium,
      fontWeight:500,
    },
  },
  error: {
    color: "red",
    fontSize: "14px",
    margin: "4px 14px 0",
    fontFamily: `'DM Sans', sans-serif`,
    [theme.breakpoints.down("450")]: {
      fontSize: "12px",
    },
  },
  link: {
    color: theme.palette.primary.extraDark,
    textDecoration:"underline",
    fontFamily:theme.typography.fontFamily.medium,
    fontWeight:500,
    [theme.breakpoints.down("450")]: {
      fontSize: "14px",
    },
  },
  responsiveCaptcha: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection:"column",
    "@media (max-width: 430px)": {
      transform: "scale(0.75)",
    },
  }, 
  adornmentIcon: {
    '& .MuiIconButton-root': {
      backgroundColor: 'unset !important',
      border: 'unset !important',
      '& span':{
        color: theme.palette.black.extraDark
      }
    }
  },
  textBtn:{
    color: theme.palette.primary.extraDark,
    textDecoration:"underline",
    fontFamily:theme.typography.fontFamily.medium,
    fontWeight:500,
    fontSize:"16px",
    [theme.breakpoints.down("450")]: {
      fontSize: "14px",
    },
    background: "transparent",
    border: "none",
    cursor:"pointer"
  }
  
}));

export default useStyles;
