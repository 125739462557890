// typingActions.js

const START_TYPING = 'START_TYPING';
const STOP_TYPING = 'STOP_TYPING';

export const startTyping = (displayName) => ({
  type: START_TYPING,
  payload: displayName,
});

export const stopTyping = (displayName) => ({
  type: STOP_TYPING,
  payload: displayName,
});
