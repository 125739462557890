import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    howToUseHeading: {
        backgroundColor: theme.palette.primary.dark,
        maxWidth: "60%",
        padding: "10px",
        [theme.breakpoints.down('md')]: {
            maxWidth: "80%",
        },
    },
    closeIcon: {
      right: "43px",
      minHeight: "30px",
     "&>.MuiIconButton-label":{
     color : theme.common.black,
  
     },
      "@media (max-width:360px)": {
        top: "8px",
      },
    },
    grayCircleBtn: {
      border: "none",
      backgroundColor: theme.common.white,
      minWidth: "37px",
      "&:hover": {
             opacity: ".8",
             backgroundColor: theme.common.white,
      },
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "-5px",
        right: "-5px",
        color: theme.common.white,
      },
    },
    topHeadingBg:{
      position:"relative",
      fontSize:"26px",
      fontWeight: 700,
      backgroundColor:theme.common.white, 
      color:"#010101",
      width:"100%",  
      borderRadius: "20px 20px 0 0",
      [theme.breakpoints.down('sm')]: {
        fontSize:"16px",
    },
    },
    howToUseBtn: {
        cursor: "pointer",
    },
    btnStyle:{
        letterSpacing: "0",
                        "&:hover": { color: theme.palette.primary.dark,},
        '&:focus-visible': {
          outline:"none",
          color: theme.palette.primary.dark,
        },
    },
    actionButton: {
        margin: theme.spacing(0.5, 0.75),
        padding: theme.spacing(0, 1),
        "& .MuiSvgIcon-root": {
          fontSize: "21px",
        },
        [theme.breakpoints.only("sm")]: {
          margin: theme.spacing(0.5, 0.5),
        },
        [theme.breakpoints.only("xs")]: {
          margin: "4px 10px",
        },
    },
   
    videoView: {
        backgroundColor: '#fff',
        width:'60%',
        height: 'auto',
        borderRadius: "0 0 20px 20px",
        padding: "0 48px 48px",
        margin:'0 auto',
        [theme.breakpoints.down('md')]: {
            width: "80%",
            padding: "0 24px 24px",
        },
    },
    footerLink: {
      fontSize:20,
      display: "block",
      color: theme.common.gary_200,
      padding: theme.spacing(1.5, 0),
      fontFamily: theme.typography.fontFamily.regular,
      fontWeight: 400,
           [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeight: "14px",
      },
    },
    howTouseTopCloseIcon:{
      position:"absolute !important",
      cursor:"pointer",
      padding:"6px !important", 
      minWidth:"32px"
    },

  }));

export default useStyles;
