import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  modalPaper: {
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formDesign: {
    width: "40vw",
    overflowY: "auto",
    padding: "10px 25px",
    marginBottom: "12px",
    maxHeight: "70vh", 
    [theme.breakpoints.down("lg")]: {
      maxHeight: "60vh", 
    },
    [theme.breakpoints.down("md")]: {
      width: "80vw",
      maxHeight: "60vh", 
    },
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
      padding: "20px",
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      '@media (orientation: landscape)': {
        maxHeight:"50vh"
      }
    },
  },
  formLabelStyle: {
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: "14px",
    color: theme.common.netural50,
  },
  inputBorder: {
    "& .MuiOutlinedInput-root": {
      fontSize: "16px",
      border: `1px solid ${theme.common.lightGrayBorder}`,
      borderRadius: "31px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 28px",
      [theme.breakpoints.down("800")]: {
        padding: "14px",
      },
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "0",
    },
    "& .MuiSelect-select:focus":{
      backgroundColor: theme.common.white,
      borderRadius:"inherit !important"
    } 
  },
  btnStyle: {
    letterSpacing: "0",
    "&:hover": { color: theme.palette.primary.dark },
    "&:focus-visible": {
      outline: "none",
      color: theme.palette.primary.dark,
    },
  },
  reportLink: {
    cursor: "pointer",
    color: theme.common.black,
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  inputField: {
    width: "100%",
  },
  inputTextField: {
    width: "100%",
  },
  modalbtn: {
    paddingLeft: "35px",
    paddingRight: "35px",
    width: "100%",
    margin: "0 auto",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  responsiveCaptcha: {
    "@media (max-width: 430px)": {
      transform: "scale(0.75)",
    },
  }, 
  labelAsterisk: {
    color: "red",
  },
  attachmentView: {
    display: "flex",
    alignItems: "center",
    flexDirection:"column"
  },
  attachmentViewAction: {
    display: "flex",
    fontSize: "20px",
  },
  attachmentLabel: {
    paddingTop: "12px",
    paddingLeft: "10px",
  },
  attachmentUpload: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "170px",
    marginLeft: "10px",
  },
  attachmentDeleteIcon: {
    float: "right",
    fontSize: "large",
    marginLeft: "10px",
    marginTop: "10px",
  },
  closeIcon: {
    position: "relative",
    padding: "6px !important",
    right: "unset",
    minHeight: "32px !important",
    minWidth: "32px !important",
    background: `${theme.common.white} !important`,
    border: "none",
    "& .MuiSvgIcon-root": {
      color: theme.common.black,
    },
    "&:hover": {
      color: "0, 0, 0, 0.54",
      opacity: 0.8,
      background: `${theme.common.borderColor} !important`,
    },
  },
  headerLinks: {
    fontSize: 18,
    display: "block",
    color: theme.common.neutral100,
    padding: theme.spacing(1.5, 0),
    fontFamily: theme.typography.fontFamily.medium,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "14px",
    },
  },
  cancelBtn:{
    margin:"6px",
    padding: '0 !important',
    border: 'none  !important',
    backgroundColor: `${theme.palette.info.main} !important`,
    minWidth: '36px !important',
    minHeight: '36px !important',
    "& span":{ color: 'rgba(0, 0, 0, 0.87)' },
    "&:hover": { backgroundColor: '#d5d5d5 !important' }    
  },
  mandatory: {
    color: theme.palette.error.main,
  },
  error: {
    color: "red",
    fontSize: "14px",
    margin: "4px 14px 0",
    [theme.breakpoints.down("450")]: {
      fontSize: "12px",
    },
  },

  otpTextField: {
    '&.MuiTextField-root': {
      width: '40px',
      height: '40px',
      margin: '5px',
      border: `1px solid ${theme.common.borderColor}`, 
      borderRadius: '4px', 
                                    
    },
    '& .MuiInput-root': {
      height: "40px",
      
    },
    '& .MuiInputBase-inputMarginDense': {
      textAlign:"center"
    }
  },
  otpModal: {
    padding: '20px',
    outline: 0,
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    [theme.breakpoints.down("450")]: {
      maxWidth: 330,
    },
  },
  otpLoader: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    position:"absolute",
    top:'0px',
    left:'0px',
    right:'0px',
    bottom:'0px',
    backgroundColor:theme.common.circleBtnColor,
    zIndex:2
  },
  otpCloseIcon: {
    position: "relative",
    padding: "4px !important",
    right: "unset",
    minHeight: "24px !important",
    minWidth: "24px !important",
    background: `${theme.common.white} !important`,
    border: "none",
    "& .MuiSvgIcon-root": {
      color: theme.common.black,
    },
    "&:hover": {
      color: "0, 0, 0, 0.54",
      opacity: 0.8,
      background: `${theme.common.borderColor} !important`,
    },
  },
}));
export default useStyles;
