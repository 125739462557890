import React, { useRef, useEffect, useState } from 'react';
import { Modal, Typography, Box, Paper, TextField, FormControl, Button, Tooltip, IconButton, } from "@material-ui/core";
import useStyles from "./ReportIssueStyles";
import CloseIcon from "@material-ui/icons/Close";
import { makeRequest } from "../../utils/utils";
import { API_ENDPOINT, METHOD_TYPES } from "../../utils/constants";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from '@material-ui/core/CircularProgress';


const OtpInput = ({
    otpPopupOpen,
    setOtpPopupOpen,
    userEmail,
    handleSubmit,
    otp,
    setOtp,
    alertMessage,
    setAlertMessage,
    otpLength,
    resetFormFields}) => {
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const inputRef = useRef([]);


    const handleChange = (index, e) => {
        const value = e.target.value;
        if (value === '') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
            return;
        }
        if (isNaN(value) || value.trim() === '')
            return;
        const newOtp = [...otp];
        newOtp[index] = value.substring(value.length - 1);
        setOtp(newOtp);

        if (value && index < otp.length - 1 && inputRef.current[index + 1]) {
            inputRef.current[index + 1].focus();
        }
    }
    const handleClick = (index) => {
        inputRef.current[index].setSelectionRange(1, 1)
    }
    const handleKeyDown = (index, e) => {

        if (e.key === 'Backspace' && !otp[index] && index > 0 && inputRef.current[index - 1]) {
            inputRef.current[index - 1].focus()
        }
        else if (e.key === 'Enter') {
            handleValidateOtp();
        }
    }
    const handleValidateOtp = async () => {
        setIsLoading(true)
        try {
            const payload = {
                email: userEmail,
                verificationCode: otp.join("")
            };
            const response = await makeRequest(
                METHOD_TYPES.POST,
                API_ENDPOINT.VERIFY_OTP,
                payload
            );
            if (response.statusCode === 200) {
                handleSubmit();
                setAlertMessage({
                    type: "success",
                    message: "Thank you for reaching out! Your message has been successfully received.",
                });
                setTimeout(() => {
                    setOtpPopupOpen(false);
                }, 3000);

            }

            else {
                setAlertMessage({
                    type: "error",
                    message: response.message,
                });
                setOtp(new Array(otpLength).fill(''));
                inputRef.current[0].focus(); 
            }
            setIsLoading(false)
        } catch (error) {
            setAlertMessage({
                message: "An unexpected error occurred",
                severity: "error",
            });
            setIsLoading(false)
        }
    }
    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text');
        const newOtp = otp.map((_, index) => paste[index] || '');
        setOtp(newOtp);

        // Move focus to the appropriate input field
        newOtp.forEach((value, index) => {
            if (value && inputRef.current[index]) {
                inputRef.current[index].focus();
            }
        });
    };
    const handleClose = () => {
        setOtpPopupOpen(false)
        resetFormFields();
        inputRef.current[0].focus();
    }

    return (
        <Modal
            className={classes.modalPaper}
            open={otpPopupOpen}
            aria-labelledby="otp-popup-title"
            aria-describedby="otp-popup-description"
        >

            <Box
                component={Paper}
                className={classes.otpModal}

            >
                {isLoading && (
                    <Box
                        className={classes.otpLoader}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <Box>
                    {alertMessage?.type === "success" && (
                        <Alert
                            // variant="filled"
                            iconMapping={{
                                success: <CheckCircleOutlineIcon fontSize="inherit" />,
                            }}
                            style={{marginBottom:'12px'}}
                        >
                            {alertMessage.message}
                        </Alert>
                    )}
                    {alertMessage?.type === "error" && (
                        <Alert  severity="error" style={{fontSize:"14px", marginBottom:'12px'}}>
                            {alertMessage.message}
                        </Alert>
                    )}

                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative" >
                    <Box flexGrow={1} textAlign={"center"}>
                        <Typography variant="h5" id="otp-popup-title" >
                            Enter OTP sent to email
                        </Typography>
                    </Box>
                    <Tooltip title="Close" style={{marginLeft:'10px'}}>
                        <IconButton
                            className={`${classes.otpCloseIcon}`}
                            onClick={handleClose}
                        >
                            <CloseIcon style={{ fontSize: "15px" }} />
                        </IconButton>
                    </Tooltip>
                </Box>

                <FormControl fullWidth margin="normal">
                    <Box display="flex" justifyContent="space-between" gap={2}>
                        {otp.map((value, index) => {
                            return <TextField
                                inputProps={{
                                    maxLength: 1,
                                }}
                                className={classes.otpTextField}
                                size="small"
                                key={index}
                                value={value}
                                type='text'
                                onChange={(e) => handleChange(index, e)}
                                onClick={() => handleClick(index)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                inputRef={(input) => (inputRef.current[index] = input)}
                                onPaste={handlePaste}

                            />
                        })}
                    </Box>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleValidateOtp}
                    disabled={otp.some(val => val === '')}
                    style={{ marginTop: '20px' }}
                >
                    Validate and Submit
                </Button>
            </Box>

        </Modal>
    )
}

export default OtpInput;