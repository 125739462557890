import React, { useRef, useEffect, useState } from "react";
import Header from "../../components/shared/Header/Header";
import Footer from "../../components/shared/Footer/Footer";
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  withWidth,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip
} from "@material-ui/core";
import useStyles from "./HomeStyles";
import { useLocation } from 'react-router-dom';
import conversion from "../../images/conversion.webp";
import friendsFamily from "../../images/friendsFamily.webp";
import Collaborative from "../../images/Collaborative.webp";
import tryTalkroom from "../../images/tryTalkroom_new.webp";
import { store } from "../../store";
import union from "../../images/union_new.webp";
import Heading from "./Heading/Heading";
import SubHeading from "./SubHeading/SubHeading";
import Virtial from "../../images/Virtial.webp";
import mgo from "../../images/mgo.webp";
import signInWorks from "../../images/signInImg.webp";
import copyLinksWorks from "../../images/copyLinksWorks.webp";
import collaborate from "../../images/collaborate.webp";
import Vector from "../../images/Vector.webp";
import VectorTryNow from "../../images/VectorTryNow.webp";
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import ModalBox from "../../components/shared/ModalBox/ModalBox"
import Register from "../Auth/Register/Register";
import Login from "../Auth/Login/Login"
import Dashboard from "../Auth/Dashboard";
import HomeJoinForm from "./HomeJoinForm/HomeJoinForm";
import JoinForm from "./JoinForm/JoinForm";
import ForgetPassword from "../Auth/ForgetPassword/ForgetPassword";
import { fetchFile } from "../../utils/awsUtil";
import { AWS_VIDEO_UPLOAD_DIRECTORY_PATH } from "../../utils/constants";
import CloseIcon from "@material-ui/icons/Close"; 


/**
 * Name : Home
 * Desc : Show the landing page with all details
 */

const Home = ({width,  ...props}) => {
  const classes = useStyles();
  const scollToRef = useRef();
  const specificContentRef = useRef();
  const location = useLocation();
  const isLoggedIn = !!store.getState()?.settings?.userData?.token || false;
  const [modalState, setModalState] = useState({ open: false,
    title: '',
    component: <></>
  });
  const [signedUrl, setSignedUrl] = useState();
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);

  const handleVideoDialogOpen = () => {
    setVideoDialogOpen(true);
  };

  const handleVideoDialogClose = () => {
    setVideoDialogOpen(false);
  };


  const handleClickOpenPopup = (componentName, componentTitle ) => {
    setModalState({
      open: true,
      title: componentTitle,
      component: componentModal[componentName]
    });
  }

  const handleClickClosePopup = () => {
    setModalState({
      open: false,
      title: '',
      component: <></>
    });
  };


  const handleVideoPlay = async () => {
    const uri = AWS_VIDEO_UPLOAD_DIRECTORY_PATH;
    const signedUrlInfo = await fetchFile(uri);
    
    setSignedUrl(signedUrlInfo);
  };

  useEffect(() => {
    handleVideoPlay();
  }, []);
  
  
 const  componentModal = {
  login: <Login handleClickClosePopup = {handleClickClosePopup} handleClickOpenPopup = {handleClickOpenPopup} {...props}/>,
  register : <Register handleClickClosePopup ={handleClickClosePopup} handleClickOpenPopup = {handleClickOpenPopup}/>,
  joinRoom:<JoinForm {...props}/>,
  forgetPassword: <ForgetPassword handleClickClosePopup ={handleClickClosePopup} handleClickOpenPopup = {handleClickOpenPopup} {...props}/>
 }



  const homeHeading = [
    {
      registerHeading: "Sign In",
      makeARoomSubHeading: "Enter your login details to connect instantly.",
    },
    {
      shareLinkHeading: "Copy link",
      shareLinkSubHeading:
        " Invite friends to the room by copy and share the URL.",
    },
    {
      collaborateHeading: "Collaborate",
      collaborateSubHeading:
        "Efficiently collaborate using creative whiteboards, shared screens, live chat, and file sharing.",
    },
    {
      shareHeading: "Record your conversations",
      shareSubHeading:
        " Record your sessions on cloud, access via password protected link and share with others. ",
    },
    {
      collaborativeHeading: "Collaborative file and document sharing",
      collaborativeSubHeading:
        " Exchange documents securely with other participants over the conversations.",
    },
  ];

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  useEffect(() => {
    if(!isLoggedIn){
      const urlParams = new URLSearchParams(location.search);
      const isGuestParam = urlParams.get('isGuest');
      if(isGuestParam === 'true'){
        handleClickOpenPopup('joinRoom', 'Join as a guest')
      }
    }
  
  }, [location]);

  return (
    <>
    
    <Grid
          item
          md={12}
          sm={12}
          xs={12}
          alignContent="center"
          className={classes.headerGrid}
        >
            <Header handleClickOpenPopup = {handleClickOpenPopup} {...{ specificContentRef }} />
        </Grid>
        {/* Body Content start here  */}
      <Box className={classes.homeParent}>
        <Container>
          <Box py={isLoggedIn ? width === "xs" || width === "sm" ? 0 : 7 : 0}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={isLoggedIn ? 7 : 12}>
                  <Box mt={width === "xs" ? 3 : 5} textAlign={isLoggedIn ? width === "xs" || width === "sm" ? "center" : "left" : "center"} ref={scollToRef} maxWidth={isLoggedIn ? width === "xs" || width === "sm" ? "100%" : 700 : "100%"} pb={isLoggedIn ? width === "xs" || width === "sm" ? 2 : 10 : 0} height="100%" position="relative">
                  <Typography
                      variant={isLoggedIn ? "h2" :"h1"}
                      component="h1"
                      className={classes.bannerHeading}
                    >
                      Audio and video conferences for everyone
                    </Typography>
                    <HomeJoinForm isLoggedIn={isLoggedIn} handleClickOpenPopup ={handleClickOpenPopup} {...props} />
                  </Box>
                </Grid>
                {isLoggedIn && (
                  <Grid item xs={12} sm={12} md={5}>
                    <Box bgcolor='#026EDD0D;' p={2} borderRadius={16}> 
                      <Dashboard/>
                    </Box>
                  </Grid>
                )}
            </Grid>
        </Box>
          
        {!isLoggedIn && 
           <Hidden only="xs">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={width === "xs" || width === "sm" || width === "md" ? 0 : -5}
            >
              <img src={union} alt="union" style={{maxWidth:'100%'}}/>
            </Box>
          </Hidden>
}
        </Container>
        {/* banner end */}
       
        <Box mt={4} bgcolor="common.black" py={width === "xs" || width === "sm" ? 5 : 12} ref={specificContentRef} position='relative'>
            <Container>
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.howItWorksHeading}
                  >
                    How it works?
                  </Typography>

                <Grid container spacing={4} justifyContent="center">
                  <Grid item xs={12} sm={12} md={4}>
                      <Box textAlign="center"><img src={signInWorks} alt="signInWorks" width={width === "xs" || width === "sm" ? "auto" :"100%"}/></Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box textAlign="center"> <img src={copyLinksWorks} alt="copyLinksWorks" width={width === "xs" || width === "sm" ? "auto" :"100%"}/></Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box textAlign="center"><img src={collaborate} alt="collaborateWorks" width={width === "xs" || width === "sm" ? "auto" :"100%"}/></Box>
                  </Grid>
                </Grid>
            </Container>
            <Box position='absolute' top={95} right={70}><img src={Vector} alt="HowitworkVector"/></Box>
        </Box>
            {/* How it works end */}
            {/* keepConversation start */}
            <Box my={width === "xs" || width === "sm" ? 6 : 15}>
              <Container>
                <Grid
                  container
                  spacing={4}
                  alignItems="center"
                  className={classes.paddingResp}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Box textAlign="left" maxWidth={width === "sm" ? '100%' : '470px'}>
                      <Typography
                        variant="h3"
                        component="h3"
                        className={classes.conversationHeading}
                      >
                        Keep the conversation going without delay
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={classes.conversationSubHeading}
                      >
                        Explore our wide range of features from video calling to
                        Full screen for 1 participants to unlimited number of
                        participants
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box textAlign="center">
                      <img
                        src={conversion}
                        alt="keepConversation"
                        width={width === "xs" || width === "sm" ? "auto" : "100%"}
                      />
                    </Box>
                  </Grid>
                </Grid>
                 {/* ShareRecord start  */}
                <Box my={width === "xs" || width === "sm" ? 6 : 12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box bgcolor="primary.extraLight" borderRadius={16} overflow='hidden'>
                          <Box height={width === "xs" || width === "sm" ? "auto" : 415} textAlign='center'>
                            <img
                              height="auto"
                              src={friendsFamily}
                              alt="friendsFamily"
                              width={width === "xs" || width === "sm" ? "auto" : "100%"}
                            />
                          </Box>
                          <Box textAlign='left' p={width === "xs" || width === "sm" ? 2 : 4.5}>
                            <Box pr={6}><Heading headingText={homeHeading[3].shareHeading} /></Box>
                            <SubHeading
                              subHeadingText={homeHeading[3].shareSubHeading}
                            />
                          </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box bgcolor="secondary.dark" borderRadius={16} height='100%'>
                          <Box height={width === "xs" || width === "sm" ? "auto" : 415} display='flex' justifyContent='center' alignItems='center' padding={3}>
                            <img
                              height="auto"
                              src={Collaborative}
                              alt="Collaborative"
                            />
                          </Box>
                          <Box textAlign='left' p={width === "xs" || width === "sm" ? 2 : 4.5}>
                            <Heading
                              headingText={homeHeading[4].collaborativeHeading}
                            />
                            <SubHeading
                              subHeadingText={homeHeading[4].collaborativeSubHeading}
                            />
                          </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* ShareRecord end */}

                {/* virtual whiteboarding start */}
                <Box>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                  >
                     <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                    >
                        <Box textAlign='center'><img src={Virtial} alt="Virtial" width={width === "xs" || width === "sm" ? "auto" : '100%'} /></Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box pl={width === "xs" || width === 'sm' ? 0 : 8} textAlign='left'>
                        <Typography
                          variant="h3"
                          component="h3"
                          className={classes.conversationHeading}
                        >
                          Unleash creativity with secure virtual whiteboarding
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          className={classes.conversationSubHeading}
                        >
                          Utilize the Virtual Whiteboard to encourage creativity and
                          collaboration, whether collaborating with a remote team or
                          facilitating brainstorming sessions.
                        </Typography>
                      </Box>
                    </Grid>
                   
                  </Grid>
                </Box>
                {/* virtual whiteboarding end */}
              </Container>
            </Box>
            {/* keepConversation end */}
            
            
         

      <Box py={width === "xs" ? 5 : 9} className={classes.tryTalkroom} position='relative'>
        <Box textAlign="center" px={2}>
          <Typography
            variant="h3"
            component="h3"
            className={classes.conversationHeading}
          >
            Try Talkroom now
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.conversationSubHeading}
          >
            Experience awesome collaborative sessions with TalkRoom's features.
          </Typography>
          <Box mt={width === "xs" ? 2 : 3} display='flex' justifyContent='center' flexWrap='wrap' style={{gap:'12px'}}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                scollToRef.current.scrollIntoView({ behavior: "smooth" })
              }
              style={{height:52, minWidth:163}}
            >
              Try it Now
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              style={{height:52, minWidth:163}}
              startIcon={<PlayArrowOutlinedIcon fontSize='large'/>}
              className={classes.outlineBtn}
              onClick={handleVideoDialogOpen}
            >
              Watch Demo
            </Button>
          </Box>
          {/* Dialog for Watch Demo Video */}
          <Dialog
        open={videoDialogOpen}
        onClose={handleVideoDialogClose}
        fullWidth
        maxWidth="md"
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="watch-demo-dialog-title"
        aria-describedby="watch-demo-dialog-description"
      >
        <DialogTitle className={classes.dtitle} id="watch-demo-dialog-title">
        <Box
              component="h2"
              color="common.white"
              textAlign="center"
              className={`${classes.topHeadingBg} ${classes.howToUseHeading}`}
            >
              Watch Demo
              <Tooltip title="close">
                <IconButton
                  className={`${classes.howTouseTopCloseIcon} ${classes.grayCircleBtn} ${classes.closeIcon}`}
                  onClick={handleVideoDialogClose}
                >
                  <CloseIcon style={{ fontSize: "20px" }} />
                </IconButton>
              </Tooltip>
            </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {signedUrl ? (
            <video controls autoPlay>
              <source src={signedUrl} type="video/mp4" />
            </video>
          ) : (
            <Typography variant="h6" color="error">
              Unable to load video. Please try again.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
        </Box>
       <Hidden only={["xs", "sm", "md"]}> <Box position='absolute' top={95} left={70} style={{transform:'rotate(180deg)'}}><img src={VectorTryNow} alt="tryNowVector"/></Box></Hidden>
      </Box>
      <Box mt={width === "xs" ? -50 : -52} mb={width === "xs" ? 6 : 15} maxWidth={1032} mx="auto" position='relative' zIndex={2} textAlign='center'>
        <Hidden only={["xs", "sm"]}>
          <img
            src={tryTalkroom}
            alt="tryTalkroom"
            width="100%"
          />
        </Hidden>
          <img
            src={mgo}
            alt="mgo"
            width={width === "xs" || width === "sm" ? "auto" :"100%"}
            className={classes.mgoMobileImage}
          />
          <Box className={classes.mobileImageOverlay}></Box>
          <Hidden only={["xs", "sm", "md"]}><Box position='absolute' bottom={220} right={-82}><img src={VectorTryNow} alt="tryNowVector"/></Box></Hidden>
        </Box>
      </Box>
      <Box className={classes.footerBg}>
        <Container>
          <Footer />
        </Container>
      </Box>
       {modalState.open && 
      <ModalBox modalState={modalState} handleClickClosePopup={handleClickClosePopup}>
        {modalState.component}
      </ModalBox>}
    </>
  );
};

export default withWidth()(Home);
