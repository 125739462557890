import axios from 'axios';
import hark from 'hark';
import randomString from 'random-string';
import * as chatActions from './actions/chatActions';
import * as consumerActions from './actions/consumerActions';
import * as fileActions from './actions/fileActions';
import * as lobbyPeerActions from './actions/lobbyPeerActions';
import * as meActions from './actions/meActions';
import * as notificationActions from './actions/notificationActions';
import * as peerActions from './actions/peerActions';
import * as peerVolumeActions from './actions/peerVolumeActions';
import * as producerActions from './actions/producerActions';
import * as requestActions from './actions/requestActions';
import * as recorderActions from './actions/recorderActions';
import * as roomActions from './actions/roomActions';
import * as settingsActions from './actions/settingsActions';
import * as toolareaActions from './actions/toolareaActions';
import * as typingActions from "./actions/typingActions";
import Logger from './Logger';
import { getSignalingUrl } from './urlFactory';
import { awsUploadFile, fetchFile, uniqueFileName, uuIdv4 } from './utils/awsUtil';
import { recorder } from './BrowserRecorder';
import { generateExtraData, scrollToMessageId,  truncateString } from './utils/utils';
import { ALERT_SOUND_TYPE } from './utils/constants';
import isElectron from "is-electron";


let mediasoupClient;

let io;

let ScreenShare;

let Spotlights;

let defaultResolution;

if (process.env.NODE_ENV !== 'test') {
  ({
     defaultResolution,
  } = window.config);
}

const logger = new Logger('RoomClient');
const FRAME_RATE_SCREEN_SHARE = 5;

const VIDEO_CONSTRAINS = {
  low: {
    width: { ideal: 320 },
    aspectRatio: 1.55,
    frameRate: { ideal: 15, max: 15 }
  },
  medium: {
    width: { ideal: 640 },
    aspectRatio: 1.78,
    frameRate: { ideal: 15, max: 15 }
  },
  high: {
    width: { ideal: 1280 },
    aspectRatio: 1.78,
    frameRate: { ideal: 15, max: 15 }
  },
  veryhigh: {
    width: { ideal: 1920 },
    aspectRatio: 1.78,
    frameRate: { ideal: 5, max: 5 }
  },
  ultra: {
    width: { ideal: 3840 },
    aspectRatio: 1.78,
    frameRate: { ideal: 5, max: 5 }
  },
};
const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

const VIDEO_SIMULCAST_ENCODINGS = [
  { scaleResolutionDownBy: 4 },
  { scaleResolutionDownBy: 2 },
  { scaleResolutionDownBy: 1 },
];

// Used for VP9 webcam video.
const VIDEO_KSVC_ENCODINGS = [{ scalabilityMode: 'L1T1' }];

// Used for VP9 desktop sharing.
const VIDEO_SVC_ENCODINGS = [{ scalabilityMode: 'L1T1', dtx: true }];

let store;

let intl;

const EVN_CONFIGURATION = {
  requestTimeout: 30000,
  transportOptions: {
    tcp: true,
  },
  turnServers: [
    {
      urls: ['turn:turn.example.com:443?transport=tcp'],
      username: 'example',
      credential: 'example',
    },
  ],
};

const ROOM_OPTIONS = {
  requestTimeout: EVN_CONFIGURATION.requestTimeout,
  transportOptions: EVN_CONFIGURATION.transportOptions,
  turnServers: EVN_CONFIGURATION.turnServers,
};

const RECONNECTING_TIME = 30 * 1000; // SEC // 30

export default class RoomClient {
  /**
   * @param  {Object} data
   * @param  {Object} data.store - The Redux store.
   * @param  {Object} data.intl - react-intl object
   */
  static init(data) {
    store = data.store;
    intl = data.intl;
  }

  constructor({
    peerId,
    accessCode,
    device,
    useSimulcast,
    useSharingSimulcast,
    produce,
    forceTcp,
    displayName,
    muted,
  } = {}) {
    if (!peerId) throw new Error('Missing peerId');
    else if (!device) throw new Error('Missing device');

    logger.debug(
      'constructor() [peerId: "%s", device: "%s", useSimulcast: "%s", produce: "%s", forceTcp: "%s", displayName ""]',
      peerId,
      device.flag,
      useSimulcast,
      produce,
      forceTcp,
      displayName
    );

    this._signalingUrl = null;

    // Closed flag.
    this._closed = false;

    // Whether we should produce.
    this._produce = produce;

    // Whether we force TCP
    this._forceTcp = forceTcp;

    // Use displayName
    if (displayName)
      store.dispatch(settingsActions.setDisplayName(displayName));

    // Torrent support
    this._torrentSupport = null;

    // Whether simulcast should be used.
    this._useSimulcast = useSimulcast;

    if ('simulcast' in window.config)
      this._useSimulcast = window.config.simulcast;

    // Whether simulcast should be used for sharing
    this._useSharingSimulcast = useSharingSimulcast;

    if ('simulcastSharing' in window.config)
      this._useSharingSimulcast = window.config.simulcastSharing;

    this._muted = muted;

    // This device
    this._device = device;

    // My peer name.
    this._peerId = peerId;

    // Access code
    this._accessCode = accessCode;

    // Socket.io peer connection
    this._signalingSocket = null;

    // The room ID
    this._roomId = null;

    // mediasoup-client Device instance.
    // @type {mediasoupClient.Device}
    this._mediasoupDevice = null;

    // Our WebTorrent client
    // this._webTorrent = null;

    // Reconnecting time out
    this._timeout = null;

    // Is joined room successfully
    this._isRoomJoined = false;

    // time intervals
    this._timeIntervals = [];

    if (defaultResolution)
      store.dispatch(settingsActions.setVideoResolution(defaultResolution));

    // Put the browser info into state
    store.dispatch(meActions.setBrowser(device));

    // Max spotlights
    if (device.bowser.getPlatformType() === 'desktop')
      this._maxSpotlights = store.getState().settings.lastN;
    else this._maxSpotlights = store.getState().settings.mobileLastN;

    store.dispatch(settingsActions.setLastN(this._maxSpotlights));

    // Manager of spotlight
    this._spotlights = null;

    // Transport for sending.
    this._sendTransport = null;

    // Transport for receiving.
    this._recvTransport = null;

    // Local mic mediasoup Producer.
    this._micProducer = null;

    // Local mic hark
    this._hark = null;

    // Local webcam mediasoup Producer.
    this._webcamProducer = null;

    // Whiteboard initiator PeerIf
    this._whiteboardInitiator = null;

    // Map of webcam MediaDeviceInfos indexed by deviceId.
    // @type {Map<String, MediaDeviceInfos>}
    this._webcams = {};

    this._audioDevices = {};

    // mediasoup Consumers.
    // @type {Map<String, mediasoupClient.Consumer>}
    this._consumers = new Map();

    this._screenSharing = null;

    this._screenRecording = null;

    this._screenSharingProducer = null;

    this._endSessionInterval = null;


		// Receive transport restart ICE object
		this._recvRestartIce = { timer: null, restarting: false };

		// Send transport restart ICE object
		this._sendRestartIce = { timer: null, restarting: false };

    // request timers
    this._requestTimers =[];

    this._startDevicesListener();

    this._callStartTime = null;

    this._callStartTimerInterval = null;

    this._audioStream = null;     
  }

  isWhiteboardInitiator=()=>{
    return  !this._whiteboardInitiator || this._whiteboardInitiator === this._peerId;
  }

 initializeSocketEvents() {
    if (this._signalingSocket) {
      // Handle typing events
      this._signalingSocket.on("typing", (displayName) => {
        store.dispatch(typingActions.startTyping(displayName));
      });

      this._signalingSocket.on("stopTyping", (displayName) => {
        store.dispatch(typingActions.stopTyping(displayName));
      });
    }
  }

  emitTyping(displayName) {
    this._signalingSocket.emit("typing", displayName);
    store.dispatch(typingActions.startTyping(displayName));

    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    // Automatically stop typing after a delay
    this.typingTimeout = setTimeout(() => {
      this.emitStopTyping(displayName);
    }, 2000); // 3 seconds delay
  }

  emitStopTyping(displayName) {
    this._signalingSocket.emit("stopTyping", displayName);
    store.dispatch(typingActions.stopTyping(displayName));
  }

  monitorNetworkQuality = async (transport, type) => {
    const timeInterval = 2000; // Set the monitoring interval in milliseconds
    let interval = null;
    interval = setInterval(async () => {
      if (!transport) {
        return false;
      }
      if (transport.closed) {
        interval && clearInterval(interval);
        interval = null;
        return false;
      }
      try {
        const stats = await transport.getStats();
        let rtt = 0;
        let packetLossPercentage = 0;
        stats.forEach(report => {

          if (report.type === 'candidate-pair' && report.state === 'succeeded') {
            rtt = report.currentRoundTripTime; // RTT in milliseconds            
          }

          if (report.type === 'inbound-rtp') {
            const { packetsLost, packetsReceived } = report
            if (packetsLost) {
              packetLossPercentage = (packetsLost / (packetsLost + packetsReceived)) * 100;
              packetLossPercentage = parseFloat(packetLossPercentage.toFixed(1));
            }
          }

         
        });

        // Now you can use the RTT or other metrics for network quality monitoring.  
       
          this.playOrPauseVideoByNetwork({rtt, packetLossPercentage})
        
      } catch (error) {
        logger.error('monitorNetworkQuality() | failed: %o', error);
        interval && clearInterval(interval);
        interval = null;
      }
    }, timeInterval);
  }

  async playOrPauseVideoByNetwork({rtt, packetLossPercentage}) {
    try {
    const slowNetwork = packetLossPercentage > 7;
    // Temp hiding low network indication
    
    for (const consumer of this._consumers.values()) {
      if(consumer && consumer.kind === 'video' && consumer.appData.source !== 'screen') {
        if (slowNetwork) {
          if (consumer.paused || consumer.closed) return;
          consumer.pause();
        } else {
          if (!consumer.paused || consumer.closed) return;
          consumer.resume();
        }
      }
    }

      
  } catch (error) {
    logger.error('playOrPauseVideoByNetwork() | failed: %o', error);
  }
  }

  async restartIce() {
    try {
      if (this._sendTransport) {
        const iceParameters = await this.sendRequest('restartIce', {
          transportId: this._sendTransport.id,
        });
        this._sendTransport.restartIce({ iceParameters });
      }

      if (this._recvTransport) {
        const iceParameters = await this.sendRequest('restartIce', {
          transportId: this._recvTransport.id,
        });
        this._recvTransport.restartIce({ iceParameters });
      }

      if (
        !this._sendTransport ||
        !this._recvTransport ||
        !this._mediasoupDevice
      ) {
        await this.rejoinRoom();
      }

      const rejoinInfo = await this.sendRequest('rejoin');
      
      this.updateConsumer(rejoinInfo);
      return;
    } catch (error) {
      logger.error('restartIce() | failed: %o', error);

      this.close('restartIce');
      return;
    }
  }

  async updateConsumer({ peers, consumers,producers, isRoomLocked, localRecordingState, localRecordingStatePeerId, isWhiteboardOpen, whiteboardInitator }) {
    try {
 
    // Set whiteboardInitiator PeerId
    this._whiteboardInitiator = whiteboardInitator;
    
    const peerIds = peers?.map((dt) => dt.id);
    const consumerIds = consumers?.map((dt) => dt.id);
    const peersInfo = store.getState().peers;
    const consumersInfo = store.getState().consumers;
    const peerInfoIds = peersInfo ? Object.keys(peersInfo)?.map((id) => {
      return id;
    }): [];

    // peer info updating...
    if (peerInfoIds && peerInfoIds?.length > 0) {
      peerInfoIds.forEach((peerId) => {
        if (!peerIds.includes(peerId)) {
          // remove peer info from local
          store.dispatch(peerActions.removePeer(peerId));
        }
      });
    }
    
    // Update the peer display name if required
    for(let j=0; j < peers.length; j++){
      const serverPeerInfo = peers[j];
      const localDisplayName = peersInfo[serverPeerInfo.id]?.displayName;
      if (serverPeerInfo.displayName !== localDisplayName) {
        // need to update the peer display name
        store.dispatch(peerActions.setPeerDisplayName(serverPeerInfo.displayName, serverPeerInfo.id));
      }
    }


    // new peer info into local
    if (peerIds && peerIds?.length > 0) {
      peerIds.foreach((peerId, peerIdIndex) => {
        if (!peerInfoIds.includes(peerId)) {
          // new peer info into local
          const { id, displayName, picture } = peers[peerIdIndex];
          store.dispatch(
            peerActions.addPeer({
              id,
              displayName,
              picture,
              consumers: [],
              consumersInfo: [],
            })
          );

          // updating spotlights for peers
          this._spotlights.addPeers(peers);
          store.dispatch(roomActions.setSpotlights(peerIds));
          this.updateSpotlights(peerIds);
        }
      });
    }

    // checking for removed consumer...
    for (const consumer of this._consumers.values()) {
      if (!consumerIds.includes(consumer?.id)) {
        // remove consumer
        this.closeConsumer({ consumerId: consumer?.id });
      }
    }

    // checking for updating consumer...
    for (let i = 0; i < consumers.length; i++) {
      const consumerElement = consumers[i];
      const localConsumerInfo = consumersInfo[consumerElement?.id];
      if (Boolean(consumerElement?.producerPaused)) {
        if (!Boolean(localConsumerInfo?.remotelyPaused)) {
          this.pauseRemoteConsumer({
            consumerId: consumerElement?.id,
          });
        }
      } else if (Boolean(localConsumerInfo?.remotelyPaused)) {
        
          this.resumeRemoteConsumer({
            consumerId: consumerElement?.id,
          });
        
      }
    }

    // Notificating user to reconnected successfully
    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: 'socket.reconnected',
          defaultMessage: 'You are reconnected.',
        }),
      })
    );
  
    store.dispatch(roomActions.setRoomState('connected'));


    // Updating producers
    const storeProducers = store.getState().producers;
  
    for (const producerKey of Object.keys(storeProducers)) {
      
      const isFound = producers.includes(producerKey);
      if(!isFound){
        // need to remove the producer and re-create
        
        const sourceInfo = storeProducers[producerKey];
        
        // remove producer from store
        store.dispatch(producerActions.removeProducer(producerKey));

        if(sourceInfo?.source === "mic"){
          
          this._micProducer = null;
          this.enableMic();
        }

        if(sourceInfo?.source === "webcam"){
          
          this._webcamProducer = null;
          this.enableWebcam();
        }

        if(sourceInfo?.source === "screen"){
          
          this._screenSharingProducer = null;  
          this.enableScreenSharing();
        }
      }
    }

    // room lock updating
    const { locked } = store.getState().room;
    if(!!isRoomLocked !== !!locked){
      isRoomLocked
      ? store.dispatch(roomActions.setRoomLocked())
      : store.dispatch(roomActions.setRoomUnLocked());
    }

    // update recording state
    store.dispatch(
    peerActions.setPeerLocalRecordingState(
      localRecordingStatePeerId,
      localRecordingState
    )
    );


    // Check for whiteboad 
    const roomInfo = store.getState().room;
    const isWhiteboard = roomInfo.isWhiteboardOpen;
    
    if(!!isWhiteboardOpen !== !!isWhiteboard){
      isWhiteboardOpen
      ? store.dispatch(roomActions.setShowWhiteboard())
      : store.dispatch(roomActions.setHideWhiteboard());
    }


    //////////////////////// CHAT/FILE NOTIFICATION UPDATE ///////////////////////
// Updated chat/file messages and notifications
    const {
      chatHistory,
      fileHistory,
      lobbyPeers
    } = await this.sendRequest('serverHistory');
    const oldChatHistory = store.getState().chat;
    const oldFilesHistory = store.getState().files;
    const { unreadMessages, unreadFiles } = store.getState().toolarea;
  
    let newChatMessages = [], newFilesHistory = [];
    for (let i = 0; i < chatHistory.length; i++) {
      const element = chatHistory[i];
      const matchedIndex = oldChatHistory.findIndex(rs=> rs.messageId === element.messageId);
      if(matchedIndex === -1){
        // new message found after reconnecting
        newChatMessages.push(element);
      }
    }

    for (let j = 0; j < fileHistory.length; j++) {
      const element = fileHistory[j];
      // check the file url is added or not 
      if(!oldFilesHistory[element.magnetUri]){
        newFilesHistory.push(element);
      }
    }

    newChatMessages.length > 0 &&
        store.dispatch(chatActions.addChatHistory(newChatMessages));

      newFilesHistory.length > 0 &&
        store.dispatch(fileActions.addFileHistory(newFilesHistory));

      const totalUnreadMessageCount = Object.keys(newChatMessages).length;
      const totalUnreadFileCount = Object.keys(newFilesHistory).length;

      totalUnreadMessageCount > 0 &&
        store.dispatch(
          toolareaActions.setTotalUnreadMessageCount(totalUnreadMessageCount + unreadMessages)
        );

      totalUnreadFileCount > 0 &&
        store.dispatch(
          toolareaActions.setTotalUnreadFileCount(totalUnreadFileCount + unreadFiles)
        );
        
        // set lobby to default
        store.dispatch(lobbyPeerActions.setDefaultLobby());
        // set updated new peers into the lobby
        lobbyPeers.length > 0 &&
        lobbyPeers.forEach((peer) => {
          store.dispatch(lobbyPeerActions.addLobbyPeer(peer.peerId));
          store.dispatch(
            lobbyPeerActions.setLobbyPeerDisplayName(peer.displayName, peer.peerId)
          );
          store.dispatch(lobbyPeerActions.setLobbyPeerPicture(peer.picture));
        });

  //////////////////////// CHAT/FILE NOTIFICATION UPDATION END ///////////////////////

     
} catch (error) {
  logger.error('updateConsumer() | failed: %o', error);

}
  
    return;
  }

  close(closeCallFrom) {
    
try {
  
    logger.debug('close()');

    if (this._closed) return;

    if (this._signalingSocket) {
      this._signalingSocket.emit('request', { method: 'leave' });
      this._signalingSocket.close();
    }

    // Close mediasoup Transports.
    if (this._sendTransport) {
      this._sendTransport.close();
      this._sendTransport = null;
    }

    if (this._recvTransport) {
      this._recvTransport.close();
      this._recvTransport = null;
    }

    // Close mediasoup Producers.
    if (this._micProducer && this._micProducer.track)
      this._micProducer.track.stop();

    if (this._webcamProducer && this._webcamProducer.track)
      this._webcamProducer.track.stop();

    if (this._micProducer && !this._micProducer.closed) {
      this._micProducer.close();
      this._micProducer = null;
    }

    if (this._webcamProducer && !this._webcamProducer.closed) {
      this._webcamProducer.close();
      this._webcamProducer = null;
    }

    if (this._screenSharingProducer) {
      this._screenSharingProducer.close();
      this._screenSharingProducer = null;
    }

    if (this._mediasoupDevice) {
      this._mediasoupDevice = null;
    }

    if (this._audioStream) {
      this._audioStream.stop();
      this._audioStream = null;
    }

    this._closed = true;

    store.dispatch(roomActions.reInitializeRoomStore());

    store.dispatch(settingsActions.setFacingMode("user"));
    
  

    if(this._callStartTimerInterval){
      clearInterval(this._callStartTimerInterval);
      this._callStartTimerInterval = null;
      this._callStartTime = null;
    }

    if(!this._isRoomJoined && closeCallFrom === 'disconnectFromServer'){
      window.location = '/' + this._roomId;
    }

} catch (error) {
  logger.error('RoomCLientclose() | failed: %o', error);
}
  }

  _startKeyListener() {
    try {
  
    // Add keypress event listener on document
    document.addEventListener('keypress', (event) => {
      const key = String.fromCharCode(event.which);

      const source = event.target;

      const exclude = ['input', 'textarea'];

      if (exclude.indexOf(source.tagName.toLowerCase()) === -1) {
        logger.debug('keyPress() [key:"%s"]', key);

        switch (key) {
          case 'a': {
            // Activate advanced mode
            store.dispatch(settingsActions.toggleAdvancedMode());
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: 'room.toggleAdvancedMode',
                  defaultMessage: 'Toggled advanced mode.',
                }),
              })
            );
            break;
          }

          case '1': {
            // Set democratic view
            store.dispatch(roomActions.setDisplayMode('democratic'));
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: 'room.setDemocraticView',
                  defaultMessage: 'Changed layout to democratic view.',
                }),
              })
            );
            break;
          }

          case '2': {
            // Set filmstrip view
            store.dispatch(roomActions.setDisplayMode('filmstrip'));
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: 'room.setFilmStripView',
                  defaultMessage: 'Changed layout to filmstrip view.',
                }),
              })
            );
            break;
          }

          case ' ':
          case 'm': {
            // Toggle microphone
            if (this._micProducer) {
              if (!this._micProducer.paused) {
                this.muteMic();

                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: 'devices.microPhoneMute',
                      defaultMessage: 'Muted your microphone.',
                    }),
                  })
                );
              } else {
                this.unmuteMic();

                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: 'devices.microPhoneUnMute',
                      defaultMessage: 'Unmuted your microphone.',
                    }),
                  })
                );
              }
            } else {
              this.enableMic();

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: 'devices.microphoneEnable',
                    defaultMessage: 'Enabled your microphone.',
                  }),
                })
              );
            }

            break;
          }

          case 'v': {
            // Toggle video
            if (this._webcamProducer) this.disableWebcam();
            else this.enableWebcam();

            break;
          }

          default: {
            break;
          }
        }
      }
    });
        
  } catch (error) {
    logger.error('_startKeyListener() | failed: %o', error);
  }
  }

  _startDevicesListener() {
    try {

    navigator.mediaDevices.addEventListener('devicechange', async () => {
      logger.debug(
        '_startDevicesListener() | navigator.mediaDevices.ondevicechange'
      );

      await this._updateAudioDevices();
      await this._updateWebcams();

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: 'devices.devicesChanged',
            defaultMessage:
              'Your devices changed, configure your devices in the settings dialog.',
          }),
        })
      );
    });
          
  } catch (error) {
    logger.error('_startDevicesListener() | failed: %o', error);
  }
  }

  login() {
    const url = `/auth/login?id=${this._peerId}`;

    window.open(url, 'loginWindow');
  }

  logout() {
    window.open('/auth/logout', 'logoutWindow');
  }

  receiveLoginChildWindow(data) {
    try {
      
    logger.debug('receiveFromChildWindow() | [data:"%o"]', data);

    const { displayName, picture } = data;

    if (store.getState().room.state === 'connected') {
      this.changeDisplayName(displayName);
      this.changePicture(picture);
    } else {
      store.dispatch(settingsActions.setDisplayName(displayName));
      store.dispatch(meActions.setPicture(picture));
    }

    store.dispatch(meActions.loggedIn(true));

    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: 'room.loggedIn',
          defaultMessage: 'You are logged in.',
        }),
      })
    );

  } catch (error) {
    logger.error('receiveLoginChildWindow() | failed: %o', error);
  }
  }

  receiveLogoutChildWindow() {
    try {
   
    logger.debug('receiveLogoutChildWindow()');

    store.dispatch(meActions.loggedIn(false));

    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: 'room.loggedOut',
          defaultMessage: 'You are logged out.',
        }),
      })
    );
       
  } catch (error) {
    logger.error('receiveLogoutChildWindow() | failed: %o', error);
  }
  }

  _soundNotification(type = ALERT_SOUND_TYPE.NOTIFICATION) {
    try {
  
    let soundAlert = '';
    switch (type) {
      case ALERT_SOUND_TYPE.RECORD_STARTED:
        soundAlert = new Audio('/sounds/RecordingSoundAlert.mp3');
        break;
      case ALERT_SOUND_TYPE.RECORD_IN_PROGRESS:
        soundAlert = new Audio('/sounds/RecordingInProgress.mp3');
        break;
      case ALERT_SOUND_TYPE.RECORD_IN_STOPPED:
        soundAlert = new Audio('/sounds/RecordingStopped.mp3');
        break;
      case ALERT_SOUND_TYPE.NOTIFICATION:
      default:
        soundAlert = new Audio('/sounds/notify.mp3');
        break;
    }

    const alertPromise = soundAlert.play();

    if (alertPromise !== undefined) {
      alertPromise.then().catch((error) => {
        logger.error('_soundAlert.play() | failed: %o', error);
      });
    }
        
  } catch (error) {
    logger.error('_soundNotification() | failed: %o', error);
  }
  }

  notify(text) {
    store.dispatch(requestActions.notify({ text: text }));
  }

  timeoutCallback(method, data, callback) {
    let called = false;

    const interval = setTimeout((info) => {
      if (called) return;
      called = true;
      callback(new Error('Request timeout.'));
    }, ROOM_OPTIONS.requestTimeout);

    this._requestTimers.push(interval);
    return (...args) => {
      if (called) return;
      called = true;
      const intevralIndex = this._requestTimers.findIndex(rs=> rs === interval);
      
      this._requestTimers.splice(intevralIndex,1);
      
      clearTimeout(interval);

      callback(...args);
    };
  }

  async restartTransportIce(transport, ice, delay) {
    try {
  

    if (!transport) {
      

      return;
    }

    if (!ice) {
      

      return;
    }

    clearTimeout(ice.timer);
    ice.timer = setTimeout(async () => {
      if(navigator.onLine){
        try {
          if (ice.restarting) {
            return;
          }
          ice.restarting = true;
  
          const iceParameters = await this.sendRequest('restartIce', {
            transportId: transport.id,
          });
  
          await transport.restartIce({ iceParameters });
          ice.restarting = false;
          
        } catch (error) {
  
          ice.restarting = false;
          ice.timer = setTimeout(() => {
            this.restartTransportIce(transport, ice, delay * 2);
          }, delay);
        }
      }else{
        ice.restarting = false;
        ice.timer = setTimeout(() => {
          this.restartTransportIce(transport, ice, delay * 2);
        }, delay);
      }
    }, delay);
        
  } catch (error) {
    logger.error('restartTransportIce() | failed: %o', error);
  }
  }

  async rejoinRoom() {
    try {
      if (this._micProducer) {
        this._micProducer.close();
        this._micProducer = null;
      }
      if (!this._mediasoupDevice) {
        this._mediasoupDevice = new mediasoupClient.Device();
        const routerRtpCapabilities = await this.sendRequest(
          'getRouterRtpCapabilities'
        );
        await this._mediasoupDevice.load({ routerRtpCapabilities });
      }

      if (this._produce && !this._sendTransport) {
        await this.initializeSendTransport();
      }

      if (!this._recvTransport) {
        await this.initializeReceiverTransport();
      }

      // Set our media capabilities.
      store.dispatch(
        meActions.setMediaCapabilities({
          canSendMic: this._mediasoupDevice.canProduce('audio'),
          canSendWebcam: this._mediasoupDevice.canProduce('video'),
          canShareScreen:
            this._mediasoupDevice.canProduce('video') &&
            this._screenSharing.isScreenShareAvailable(),
        })
      );

      // // Don't produce if explicitly requested to not to do it.
      if (this._produce) {
        if (this._mediasoupDevice.canProduce('audio'))
          if (!this._muted) this.enableMic();

        if (this.joinVideo && this._mediasoupDevice.canProduce('video'))
          this.enableWebcam();
      }

      store.dispatch(roomActions.setRoomState('connected'));

      // // Clean all the existing notifications.
      store.dispatch(notificationActions.removeAllNotifications());

      this._spotlights.start();

      this._isRoomJoined = true;
    } catch (error) {
      logger.error('rejoinRoom() | failed: %o', error);
      this.close('rejoinRoom');
    } finally {
      return;
    }
  }

  sendRequest(method, data) {
    return new Promise((resolve, reject) => {
      if (!this._signalingSocket) {
        reject('No socket connection.');
      } else {
        this._signalingSocket.emit(
          'request',
          { method, data },
          this.timeoutCallback(method, data, (err, response) => {
            if (err) {
              logger.error('sendRequest() | failed: %o', {method, data, err});
              reject(err);
            } else {
              resolve(response);
            }
          })
        );
      }
    });
  }

  sendNotification(method, data) {
    return new Promise((resolve, reject) => {
      if (!this._signalingSocket) {
        reject('No socket connection.');
      } else {
        this._signalingSocket.emit('notification', { method, data }, () => {
          resolve();
        });
      }
    });
  }

  async changeDisplayName(displayName) {
    logger.debug('changeDisplayName() [displayName:"%s"]', displayName);

    if (!displayName) displayName = 'Guest';

    store.dispatch(meActions.setDisplayNameInProgress(true));

    try {
      await this.sendRequest('changeDisplayName', { displayName });

      store.dispatch(settingsActions.setDisplayName(displayName));

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage(
            {
              id: 'room.changedDisplayName',
              defaultMessage: 'Your display name changed to {displayName}.',
            },
            {
              displayName,
            }
          ),
        })
      );
    } catch (error) {
      logger.error('changeDisplayName() | failed: %o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'room.changeDisplayNameError',
            defaultMessage:
              'An error occurred while changing your display name.',
          }),
        })
      );
    }

    store.dispatch(meActions.setDisplayNameInProgress(false));
  }

  async changeEmail(email) {
    logger.debug('changeEmail() [email: "%s"]', email);
    store.dispatch(settingsActions.setEmail(email));
  }

  async updateProfile(data) {
    try {
      const { displayName = "", email ="" } = data;
      await this.sendRequest('changeDisplayName', { displayName, email });
      store.dispatch(settingsActions.setDisplayName(displayName));
      await this.sendRequest('updateProfile', { displayName, email });
      store.dispatch(settingsActions.setEmail(email));
      store.dispatch(
        requestActions.notify({
          text: 'Your name/email has been updated successfully.',
        })
      );
    } catch (error) {
      logger.error('updateProfile() | failed: %o', error);
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: 'An error occurred while updating your name/email.',
        })
      )
      return false;
    }
  }
  async changePicture(picture) {
    logger.debug('changePicture() [picture: "%s"]', picture);

    try {
      await this.sendRequest('changePicture', { picture });
    } catch (error) {
      logger.error('changePicture() | failed: %o', error);
    }
  }

  async sendChatMessage(chatMessage) {
    logger.debug('sendChatMessage() [chatMessage:"%s"]', chatMessage);

    try {
      store.dispatch(chatActions.addUserMessage(chatMessage.text, chatMessage.messageId, chatMessage.peerId));

      await this.sendRequest('chatMessage', { chatMessage });
    } catch (error) {
      logger.error('sendChatMessage() | failed: %o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'room.chatError',
            defaultMessage: 'Unable to send chat message.',
          }),
        })
      );
    }
  }

  saveFile(file) {
    file.getBlob((err, blob) => {
      if (err) {
        return store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'filesharing.saveFileError',
              defaultMessage: 'Unable to save file.',
            }),
          })
        );
      }

    });
  }

  async handleDownload(magnetUri, name, time) {
    try {
  
    store.dispatch(fileActions.setFileActive(magnetUri));
    const uri = magnetUri;

    const source = axios.CancelToken.source();
    axios
      .get(await fetchFile(uri), {
        cancelToken: source.token,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // set File downloading progress
          store.dispatch(
            fileActions.setFileProgress(magnetUri, percentCompleted, source)
          );
        },
      })
      .then(({ data }) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(data);
        if (this._device.platform === "mobile" && this._device.os === "ios") {
        // For iOS device, open the file in a new tab
          const newTab = window.open();
          newTab.document.write(`
          <html>
            <head>
              <title>File Download</title>
            </head>
            <body style="text-align: center; display: flex; align-items: center; justify-content: center;">
                <a href="${link.href}" download="${name}">
                  <button style="padding: 40px; background-color: #026EDD; font-size: 34px; font-weight: 600; color: white; border: none; border-radius: 50px; cursor: pointer;">
                    Download ${name}
                  </button>
                </a>
            </body>
          </html>
          `);
          newTab.document.close();
        } else {
        link.download = name;
        link.click();
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        // aset file inactive to hide the progress bar
        store.dispatch(fileActions.setFileInActive(magnetUri, time));
      });
          
    } catch (error) {
      logger.error('handleDownload() | failed: %o', error);
    }
  }

  _handleTorrent(torrent) {
    // Torrent already done, this can happen if the
    // same file was sent multiple times.
    if (torrent.progress === 1) {
      return store.dispatch(
        fileActions.setFileDone(torrent.magnetURI, torrent.files)
      );
    }

    let lastMove = 0;

    torrent.on('download', () => {
      if (Date.now() - lastMove > 1000) {
        store.dispatch(
          fileActions.setFileProgress(torrent.magnetURI, torrent.progress)
        );

        lastMove = Date.now();
      }
    });

    torrent.on('done', () => {
      store.dispatch(fileActions.setFileDone(torrent.magnetURI, torrent.files));
    });
  }

  async stopFileProcessing(fileUrl, request) {
    // abort the uploading request
    request.abort();

    // remove the uploaded file from local
    store.dispatch(fileActions.removeFile(fileUrl));
  }

  async shareFiles(files) {
    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: 'filesharing.startingFileShare',
          defaultMessage: 'Attempting to share file.',
        }),
      })
    );

    const file = files[0];
    const key = uniqueFileName(file.name);
    const messageId= uuIdv4();
    const { displayName } = store.getState().settings;
    // add file to the chat area
    store.dispatch(
      fileActions.addFile(this._peerId, key, displayName, file.name, messageId)
    );
    //set active to show the uploading percentage
    store.dispatch(fileActions.setFileActive(key));

    const callback = (evt) => {
      const uploadingPercentage = Math.round((evt.loaded / evt.total) * 100);
      // update uploading percentage
      store.dispatch(fileActions.setFileProgress(key, uploadingPercentage));
    };

    const callbackForRequest = (requestInfo) => {
      store.dispatch(fileActions.setFileRequest(key, requestInfo));
    };

    const contentType = file.type;
    const response = await awsUploadFile(
      file,
      key,
      contentType,
      callback,
      callbackForRequest
    );

    if (!response?.ETag) {
      return;
    }
    const url = response?.Key?.split('/');
    const fileUrl = url[url.length - 1];
    const time = Date.now();

    //set In active to done the uploading percentage
    store.dispatch(fileActions.setFileInActive(fileUrl, time));

    this._sendFile(fileUrl, displayName, file.name, time, messageId);
  }

  // { file, name, picture }
  async _sendFile(magnetUri, displayName, fileName, time, messageId) {
    logger.debug('sendFile() [magnetUri: %o]', magnetUri);

    try {
      await this.sendRequest('sendFile', { magnetUri, displayName, fileName, time, messageId });
    } catch (error) {
      logger.error('sendFile() | failed: %o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'filesharing.unableToShare',
            defaultMessage: 'Unable to share file.',
          }),
        })
      );
    }
  }

  async getServerHistory() {
    logger.debug('getServerHistory()');

    try {
      const {
        chatHistory,
        fileHistory,
        lastNHistory,
        locked,
        lobbyPeers,
        accessCode,
      } = await this.sendRequest('serverHistory');

      chatHistory.length > 0 &&
        store.dispatch(chatActions.addChatHistory(chatHistory));

      fileHistory.length > 0 &&
        store.dispatch(fileActions.addFileHistory(fileHistory));

      const totalUnreadMessageCount = Object.keys(chatHistory).length;

      const totalUnreadFileCount = Object.keys(fileHistory).length;

      totalUnreadMessageCount > 0 &&
        store.dispatch(
          toolareaActions.setTotalUnreadMessageCount(totalUnreadMessageCount)
        );

      totalUnreadFileCount > 0 &&
        store.dispatch(
          toolareaActions.setTotalUnreadFileCount(totalUnreadFileCount)
        );

      if (lastNHistory.length > 0) {
        logger.debug('Got lastNHistory');

        // Remove our self from list
        const index = lastNHistory.indexOf(this._peerId);

        lastNHistory.splice(index, 1);

        this._spotlights.addSpeakerList(lastNHistory);
      }

      locked
        ? store.dispatch(roomActions.setRoomLocked())
        : store.dispatch(roomActions.setRoomUnLocked());

      lobbyPeers.length > 0 &&
        lobbyPeers.forEach((peer) => {
          store.dispatch(lobbyPeerActions.addLobbyPeer(peer.peerId));
          store.dispatch(
            lobbyPeerActions.setLobbyPeerDisplayName(peer.displayName, peer.peerId)
          );
          store.dispatch(lobbyPeerActions.setLobbyPeerPicture(peer.picture));
        });

      accessCode != null &&
        store.dispatch(roomActions.setAccessCode(accessCode));
    } catch (error) {
      logger.error('getServerHistory() | failed: %o', error);
    }
  }

  async muteMic() {
    logger.debug('muteMic()');

    store.dispatch(meActions.setAudioInProgress(true));
    this._micProducer.pause();

    try {
      this.sendRequest('pauseProducer', {
        producerId: this._micProducer.id,
      });
      store.dispatch(producerActions.setProducerPaused(this._micProducer.id));

      
    } catch (error) {
      logger.error('muteMic() | failed: %o', error);
      

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'devices.microphoneMuteError',
            defaultMessage: 'Unable to mute your microphone.',
          }),
        })
      );
    }
    store.dispatch(meActions.setAudioInProgress(false));
  }

  async unmuteMic() {
    logger.debug('unmuteMic()');

    if (!this._micProducer) {
      this.enableMic();
    } else {
      store.dispatch(meActions.setAudioInProgress(true));
      this._micProducer.resume();

      try {
        await this.sendRequest('resumeProducer', {
          producerId: this._micProducer.id,
        });
        store.dispatch(
          producerActions.setProducerResumed(this._micProducer.id)
        );
      } catch (error) {
        logger.error('unmuteMic() | failed: %o', error);

        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.microphoneUnMuteError',
              defaultMessage: 'Unable to unmute your microphone.',
            }),
          })
        );
      }
      store.dispatch(meActions.setAudioInProgress(false));
    }
  }

  changeMaxSpotlights(maxSpotlights) {
    this._spotlights.maxSpotlights = maxSpotlights;

    store.dispatch(settingsActions.setLastN(maxSpotlights));
  }

  // Updated consumers based on spotlights
  async updateSpotlights(spotlights) {
    logger.debug('updateSpotlights()');

    try {
      for (const consumer of this._consumers.values()) {
        if (consumer.kind === 'video') {
          if (spotlights.indexOf(consumer.appData.peerId) > -1) {
            await this._resumeConsumer(consumer);
          } else {
            await this._pauseConsumer(consumer);
          }
        }
      }
    } catch (error) {
      logger.error('updateSpotlights() failed: %o', error);
    }
  }

  async getAudioTrack() {
    await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
  }

  async getVideoTrack() {
    await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
  }

  async startAudioTrack() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
  
      this._audioStream = stream.getAudioTracks()[0];
    } catch (error) {
      // Handle the error
    }
  }
  
  async stopAudioTrack() {
      try {
        if (this._audioStream) {
          const stream = this._audioStream.getStream();
          if (stream) {
            const audioTracks = stream.getAudioTracks();
            audioTracks.forEach(track => track.stop());
          }
          this._audioStream = null;
        }
      } catch (error) {
      // Handle the error
      }
    }

  async changeAudioDevice(deviceId) {
    logger.debug('changeAudioDevice() [deviceId: %s]', deviceId);

    store.dispatch(meActions.setAudioInProgress(true));

    try {
      const device = this._audioDevices[deviceId];

      if (!device) throw new Error('no audio devices');

      logger.debug(
        'changeAudioDevice() | new selected webcam [device:%o]',
        device
      );

      if (this._micProducer && this._micProducer.track)
        this._micProducer.track.stop();

      logger.debug('changeAudioDevice() | calling getUserMedia()');

      const {
				autoGainControl,
				echoCancellation,
				noiseSuppression,
				sampleRate,
				channelCount,
				sampleSize,
			} = store.getState().settings;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: { exact: device.deviceId },
          sampleRate,
          channelCount,
          autoGainControl,
          echoCancellation,
          noiseSuppression,
          sampleSize
        },
      });

      const track = stream.getAudioTracks()[0];

      if (this._micProducer) await this._micProducer.replaceTrack({ track });

      if (this._micProducer) this._micProducer.volume = 0;

      const harkStream = new MediaStream();

      harkStream.addTrack(track);

      if (!harkStream.getAudioTracks()[0])
        throw new Error('changeAudioDevice(): given stream has no audio track');

      if (this._hark != null) this._hark.stop();

      this._hark = hark(harkStream, { play: false });

      // eslint-disable-next-line no-unused-vars
      this._hark.on('volume_change', (dBs, threshold) => {
        // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
        //   Math.pow(10, dBs / 20)
        // However it does not produce a visually useful output, so let exaggerate
        // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
        // minimize component renderings.
        let volume = Math.round(Math.pow(10, dBs / 85) * 10);

        if (volume === 1) volume = 0;

        volume = Math.round(volume);

        if (this._micProducer && volume !== this._micProducer.volume) {
          this._micProducer.volume = volume;

          store.dispatch(peerVolumeActions.setPeerVolume(this._peerId, volume));
        }
      });
      if (this._micProducer && this._micProducer.id)
        store.dispatch(
          producerActions.setProducerTrack(this._micProducer.id, track)
        );

      store.dispatch(settingsActions.setSelectedAudioDevice(deviceId));

      await this._updateAudioDevices();
    } catch (error) {
      logger.error('changeAudioDevice() failed: %o', error);
    }

    store.dispatch(meActions.setAudioInProgress(false));
  }

  async changeVideoResolution(resolution) {
    logger.debug('changeVideoResolution() [resolution: %s]', resolution);

    store.dispatch(meActions.setWebcamInProgress(true));

    store.dispatch(settingsActions.setVideoResolution(resolution));

    try {
      const deviceId = await this._getWebcamDeviceId();

      const device = this._webcams[deviceId];

      if (!device) throw new Error('no webcam devices');

      this._webcamProducer.track.stop();

      logger.debug('changeVideoResolution() | calling getUserMedia()');

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: device.deviceId },
          ...VIDEO_CONSTRAINS[resolution],
        },
      });

      const track = stream.getVideoTracks()[0];

      await this._webcamProducer.replaceTrack({ track });

      store.dispatch(
        producerActions.setProducerTrack(this._webcamProducer.id, track)
      );

      store.dispatch(settingsActions.setSelectedWebcamDevice(deviceId));
      store.dispatch(settingsActions.setVideoResolution(resolution));

      await this._updateWebcams();
    } catch (error) {
      logger.error('changeVideoResolution() failed: %o', error);
    }
    store.dispatch(meActions.setWebcamInProgress(false));
  }

  async changeWebcam(deviceId, facingMode = "") {
    logger.debug('changeWebcam() [deviceId: %s]', deviceId);

    store.dispatch(meActions.setWebcamInProgress(true));

    try {
      const device = this._webcams[deviceId];
      const resolution = store.getState().settings.resolution;

      if (!device) throw new Error('no webcam devices');

      logger.debug('changeWebcam() | new selected webcam [device:%o]', device);
      if (this._webcamProducer && this._webcamProducer.track)
        this._webcamProducer.track.stop();

      logger.debug('changeWebcam() | calling getUserMedia()');
      let constraints = {
        video: {
          deviceId: { exact: device.deviceId },
          ...VIDEO_CONSTRAINS[resolution],
        },
      }
      if (this._device.platform === 'mobile' && !!facingMode) {
        constraints = {
          video: { facingMode: { exact: facingMode }}
        }
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (stream) {
        const track = stream.getVideoTracks()[0];
        if (track && this._webcamProducer) {
          await this._webcamProducer.replaceTrack({ track });

          store.dispatch(
            producerActions.setProducerTrack(this._webcamProducer.id, track)
          );
        } else {
          logger.warn('getVideoTracks Error: First Video Track is null');
        }
      } else {
        logger.warn('getUserMedia Error: Stream is null!');
      }
      store.dispatch(settingsActions.setSelectedWebcamDevice(deviceId));

      await this._updateWebcams();
    } catch (error) {
      logger.error('changeWebcam() failed: %o', error);
    }

    store.dispatch(meActions.setWebcamInProgress(false));
  }

  setSelectedPeer(peerId) {
    logger.debug('setSelectedPeer() [peerId:"%s"]', peerId);

    this._spotlights.setPeerSpotlight(peerId);

    store.dispatch(roomActions.setSelectedPeer(peerId));
  }

  async promoteLobbyPeer(peerId) {
    logger.debug('promoteLobbyPeer() [peerId:"%s"]', peerId);

    store.dispatch(
      lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, true)
    );

    try {
      await this.sendRequest('promotePeer', { peerId });
    } catch (error) {
      logger.error('promoteLobbyPeer() failed: %o', error);
    }

    store.dispatch(
      lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, false)
    );
  }

  // type: mic/webcam/screen
  // mute: true/false
  async modifyPeerConsumer(peerId, type, mute) {
    logger.debug('modifyPeerConsumer() [peerId:"%s", type:"%s"]', peerId, type);

    if (type === 'mic')
      store.dispatch(peerActions.setPeerAudioInProgress(peerId, true));
    else if (type === 'webcam')
      store.dispatch(peerActions.setPeerVideoInProgress(peerId, true));
    else if (type === 'screen')
      store.dispatch(peerActions.setPeerScreenInProgress(peerId, true));

    try {
      for (const consumer of this._consumers.values()) {
        if (
          consumer.appData.peerId === peerId &&
          consumer.appData.source === type
        ) {
          if (mute) {
            await this._pauseConsumer(consumer);
          } else await this._resumeConsumer(consumer);
        }
      }
    } catch (error) {
      logger.error('modifyPeerConsumer() failed: %o', error);
    }

    if (type === 'mic')
      store.dispatch(peerActions.setPeerAudioInProgress(peerId, false));
    else if (type === 'webcam')
      store.dispatch(peerActions.setPeerVideoInProgress(peerId, false));
    else if (type === 'screen')
      store.dispatch(peerActions.setPeerScreenInProgress(peerId, false));
  }

  async _pauseConsumer(consumer) {
    logger.debug('_pauseConsumer() [consumer: %o]', consumer);

    if (consumer.paused || consumer.closed) return;

    try {
      await this.sendRequest('pauseConsumer', { consumerId: consumer.id });

      consumer.pause();

      store.dispatch(consumerActions.setConsumerPaused(consumer.id, 'local'));
    } catch (error) {
      logger.error('_pauseConsumer() | failed:%o', error);
    }
  }

  async _resumeConsumer(consumer) {
    logger.debug('_resumeConsumer() [consumer: %o]', consumer);

    if (!consumer.paused || consumer.closed) return;

    try {
      await this.sendRequest('resumeConsumer', { consumerId: consumer.id });

      consumer.resume();

      store.dispatch(consumerActions.setConsumerResumed(consumer.id, 'local'));
    } catch (error) {
      logger.error('_resumeConsumer() | failed:%o', error);
    }
  }

  async sendRaiseHandState(state) {
    logger.debug('sendRaiseHandState: ', state);

    store.dispatch(meActions.setMyRaiseHandStateInProgress(true));

    try {
      await this.sendRequest('raiseHand', { raiseHandState: state });

      store.dispatch(meActions.setMyRaiseHandState(state));
    } catch (error) {
      logger.error('sendRaiseHandState() | failed: %o', error);

      // We need to refresh the component for it to render changed state
      store.dispatch(meActions.setMyRaiseHandState(!state));
    }

    store.dispatch(meActions.setMyRaiseHandStateInProgress(false));
  }

  async setMaxSendingSpatialLayer(spatialLayer) {
    logger.debug('setMaxSendingSpatialLayer() [spatialLayer:%s]', spatialLayer);

    try {
      if (this._webcamProducer)
        await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
      if (this._screenSharingProducer)
        await this._screenSharingProducer.setMaxSpatialLayer(spatialLayer);
    } catch (error) {
      logger.error('setMaxSendingSpatialLayer() | failed:"%o"', error);
    }
  }

  async setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer) {
    logger.debug(
      'setConsumerPreferredLayers() [consumerId:%s, spatialLayer:%s, temporalLayer:%s]',
      consumerId,
      spatialLayer,
      temporalLayer
    );

    try {
      await this.sendRequest('setConsumerPreferedLayers', {
        consumerId,
        spatialLayer,
        temporalLayer,
      });

      store.dispatch(
        consumerActions.setConsumerPreferredLayers(
          consumerId,
          spatialLayer,
          temporalLayer
        )
      );
    } catch (error) {
      logger.error('setConsumerPreferredLayers() | failed:"%o"', error);
    }
  }

  async setConsumerPriority(consumerId, priority) {
    logger.debug(
      'setConsumerPriority() [consumerId:%s, priority:%d]',
      consumerId,
      priority
    );

    try {
      await this.sendRequest('setConsumerPriority', { consumerId, priority });

      store.dispatch(consumerActions.setConsumerPriority(consumerId, priority));
    } catch (error) {
      logger.error('setConsumerPriority() | failed:%o', error);
    }
  }

  async requestConsumerKeyFrame(consumerId) {
    logger.debug('requestConsumerKeyFrame() [consumerId:%s]', consumerId);

    try {
      await this.sendRequest('requestConsumerKeyFrame', { consumerId });
    } catch (error) {
      logger.error('requestConsumerKeyFrame() | failed:%o', error);
    }
  }

  async _loadDynamicImports() {
    
    ({ default: ScreenShare } = await import(
      './ScreenShare'
    ));

    ({ default: Spotlights } = await import(
      './Spotlights'
    ));

    mediasoupClient = await import(
      'mediasoup-client'
    );

    ({ default: io } = await import(
      'socket.io-client'
    ));
  }

  async join({ roomId, joinVideo, peerId, extraData = null }) {
    store.dispatch(meActions.setBrowser(this._device));
    try {
      this._peerId = peerId;
    
      await this._loadDynamicImports();
      
      this._roomId = roomId;
      this._closed = false;
      store.dispatch(roomActions.setRoomName(roomId));
      store.dispatch(settingsActions.setPeerId(this._peerId));

      // Set the me peer id...
      store.dispatch(
        meActions.setMe({
          peerId: this._peerId,
          loginEnabled: window.config.loginEnabled,
        })
      );

      this._signalingUrl = getSignalingUrl(this._peerId, roomId);

      const userID = store.getState().settings.userId;
      if (!extraData) {
        const systemInfo = generateExtraData();
        const ipInfo = store.getState().settings.ipInfo || null;
        const isGuest = !!!userID || false;
        extraData = {
          ipInfo,
          systemInfo,
          isGuest
        };
      }
      this._screenSharing = ScreenShare.create(this._device);
      this._signalingSocket = io(process.env.REACT_APP_MEDIASOUP_SCOEKT_URL,{ 
        path: '/backend/socket.io',
        timeout: 30000, connect_timeout: 30000,
        query: {
          peerId: this._peerId,
          roomId: roomId,
          userId: userID,
          deviceType: isElectron() ? "electron" : "web",
          extraData: JSON.stringify(extraData)
        }
      });

      this._spotlights = new Spotlights(
        this._maxSpotlights,
        this._signalingSocket
      );

      store.dispatch(roomActions.setRoomState('connecting'));
      store.dispatch(roomActions.setSocketState(''));
      store.dispatch(roomActions.setIsRoomCreated(false));
      this._signalingSocket.on('error', (reason) => {
        store.dispatch(roomActions.setSocketState('error-1'));
        logger.error('socket error ', reason);
      });

      this._signalingSocket.on('connect_error', () => {
        store.dispatch(roomActions.setSocketState('error-2'));
        logger.error('socket error ', "connect_error");
      });

      this._signalingSocket.on('connect_timeout', () => {
        
        // reset.....
        store.dispatch(
          requestActions.notify({
            text: intl.formatMessage({
              id: 'socket.disconnected',
              defaultMessage: 'Network error, please try again later.',
            }),
          })
        );
          this.close('connect_timeout');
        
      });

      this._signalingSocket.on('connect', () => {
        logger.debug('signaling Peer "connect" event');
        
        if (this._timeout) {
          clearTimeout(this._timeout);
          this._timeout = null;
        }
      
      });

      this._signalingSocket.on('disconnect', (reason) => {
        logger.warn('signaling Peer "disconnect" event [reason:"%s"]', reason);
        
        store.dispatch(roomActions.setSocketState('error-3'));
        const {status} = store.getState().recorder.localRecordingState;
        if (this._closed) return;


        if (reason === 'io client disconnect') {
          
          store.dispatch(
            requestActions.notify({
              text: intl.formatMessage({
                id: 'socket.disconnected',
                defaultMessage: 'You have left the room.',
              }),
            })
          );
          if(status === 'start'){
            recorder.stopLocalRecording();
          }
        }

        if (reason === 'io server disconnect') {
          
          store.dispatch(
            requestActions.notify({
              text: intl.formatMessage({
                id: 'socket.disconnected',
                defaultMessage: 'You are disconnected because of session timed out.',
              }),
            })
          );
          this.close('disconnectFromServer');
          if(status === 'start'){
            recorder.stopLocalRecording();
          }
        }

        // Clear all the request timers
        
        for (let p = 0; p < this._requestTimers.length; p++) {
          const element = this._requestTimers[p];
          clearTimeout(element);
        }

        // remove all event timer
        for (let i = 0; i < this._timeIntervals.length; i++) {
          const elementInterval = this._timeIntervals[i];
          clearTimeout(elementInterval);
        }
        //
        const reconnectingReasonCheck = ['transport close', 'transport error'];
        if (reconnectingReasonCheck.includes(reason)) {
          this.offlineHandler();        
        }
        store.dispatch(roomActions.setRoomState('connecting'));
      });

      this._signalingSocket.on('reconnect_failed', () => {
        logger.warn('signaling Peer "reconnect_failed" event');
        
        store.dispatch(
          requestActions.notify({
            text: intl.formatMessage({
              id: 'socket.disconnected',
              defaultMessage: 'You are disconnected.',
            }),
          })
        );
        store.dispatch(roomActions.setSocketState('error-4'));

        this.close('reconnect_failed');
        
      });

      this._signalingSocket.on('reconnect', (attemptNumber) => {
        logger.debug(
          'signaling Peer "reconnect" event [attempts:"%s"]',
          attemptNumber
        );
        
        store.dispatch(roomActions.setSocketState(''));

        this.onlineHandler();
      });

      this._signalingSocket.on('request', async (request, cb) => {
        logger.debug(
          'socket "request" event [method:%s, data:%o]',
          request.method,
          request.data
        );
        switch (request.method) {
          case 'newConsumer': {
            const {
              peerId,
              producerId,
              id,
              kind,
              rtpParameters,
              type,
              appData,
              producerPaused,
            } = request.data;

            let codecOptions;

            if (kind === 'audio') {
              codecOptions = {
                opusStereo: 1,
              };
            }

            const consumer = await this._recvTransport.consume({
              id,
              producerId,
              kind,
              rtpParameters,
              codecOptions,
              appData: { ...appData, peerId }, // Trick.
            });

            // Store in the map.
            this._consumers.set(consumer.id, consumer);

            consumer.on('transportclose', () => {
              this._consumers.delete(consumer.id);
            });

            const { spatialLayers, temporalLayers } =
              mediasoupClient.parseScalabilityMode(
                consumer.rtpParameters.encodings[0].scalabilityMode
              );

            store.dispatch(
              consumerActions.addConsumer(
                {
                  id: consumer.id,
                  peerId: peerId,
                  kind: kind,
                  type: type,
                  locallyPaused: false,
                  remotelyPaused: producerPaused,
                  rtpParameters: consumer.rtpParameters,
                  source: consumer.appData.source,
                  spatialLayers: spatialLayers,
                  temporalLayers: temporalLayers,
                  preferredSpatialLayer: spatialLayers - 1,
                  preferredTemporalLayer: temporalLayers - 1,
                  priority: 1,
                  codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
                  track: consumer.track,
                },
                peerId
              )
            );

            // We are ready. Answer the request so the server will
            // resume this Consumer (which was paused for now).
            cb(null);

            if (kind === 'audio') {
              consumer.volume = 0;

              const stream = new MediaStream();

              stream.addTrack(consumer.track);

              if (!stream.getAudioTracks()[0])
                throw new Error(
                  'request.newConsumer | given stream has no audio track'
                );

              consumer.hark = hark(stream, { play: false });

              // eslint-disable-next-line no-unused-vars
              consumer.hark.on('volume_change', (dBs, threshold) => {
                // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
                //   Math.pow(10, dBs / 20)
                // However it does not produce a visually useful output, so let exaggerate
                // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
                // minimize component renderings.
                let volume = Math.round(Math.pow(10, dBs / 85) * 10);

                if (volume === 1) volume = 0;

                volume = Math.round(volume);

                if (consumer && volume !== consumer.volume) {
                  consumer.volume = volume;

                  store.dispatch(peerVolumeActions.setPeerVolume(peerId, volume));
                }
              });
            }

            break;
          }

          default: {
            logger.error('unknown request.method "%s"', request.method);

            cb(500, `unknown request.method "${request.method}"`);
          }
        }
      });

      this._signalingSocket.on('notification', async (notification) => {
        logger.debug(
          'socket "notification" event [method:%s, data:%o]',
          notification.method,
          notification.data
        );

        try {
          switch (notification.method) {
            case 'sessionDestroyStart': {
              const { FORCE_CLOSE_TIMEOUT } = notification.data;
              store.dispatch(roomActions.setDestroyTimer(FORCE_CLOSE_TIMEOUT));

              break;
            }

            case 'sessionDestroyEnd': {
              store.dispatch(roomActions.setDestroyTimer(0));

              break;
            }

            case 'enteredLobby': {
              store.dispatch(roomActions.setInLobby(true));

              const { displayName } = store.getState().settings;
              const { picture } = store.getState().me;

              await this.sendRequest('changeDisplayName', { displayName });
              await this.sendRequest('changePicture', { picture });
              break;
            }

            case 'signInRequired': {
              store.dispatch(roomActions.setSignInRequired(true));

              break;
            }

            case 'roomReady': {
              store.dispatch(roomActions.toggleJoined());
              store.dispatch(roomActions.setInLobby(false));

              await this._joinRoom({ joinVideo });

              break;
            }

            case 'lockRoom': {
              store.dispatch(roomActions.setRoomLocked());
              const { displayName } = notification.data;
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage(
                    {
                      id: 'room.locked',
                      defaultMessage: '{displayName} locked the room.',
                    },
                    {
                      displayName,
                    }
                  ),
                })
              );

              break;
            }

            case 'unlockRoom': {
              store.dispatch(roomActions.setRoomUnLocked());
              const { displayName } = notification.data;
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage(
                    {
                      id: 'room.unlocked',
                      defaultMessage: '{displayName} unlocked the room.',
                    },
                    {
                      displayName,
                    }
                  ),
                })
              );

              break;
            }

            case 'parkedPeer': {
              const { peerId } = notification.data;

              store.dispatch(lobbyPeerActions.addLobbyPeer(peerId));
              store.dispatch(roomActions.setToolbarsVisible(true));

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: 'room.newLobbyPeer',
                    defaultMessage: 'New participant entered the lobby.',
                  }),
                })
              );

              break;
            }

            case 'lobby:peerClosed': {
              const { peerId } = notification.data;

              store.dispatch(lobbyPeerActions.removeLobbyPeer(peerId));

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: 'room.lobbyPeerLeft',
                    defaultMessage: 'Participant in lobby left.',
                  }),
                })
              );

              break;
            }

            case 'lobby:promotedPeer': {
              const { peerId } = notification.data;

              store.dispatch(lobbyPeerActions.removeLobbyPeer(peerId));

              break;
            }

            case 'lobby:changeDisplayName': {
              const { peerId, displayName } = notification.data;

              store.dispatch(
                lobbyPeerActions.setLobbyPeerDisplayName(displayName, peerId)
              );
              break;
            }

            case 'lobby:changePicture': {
              const { peerId, picture } = notification.data;

              store.dispatch(
                lobbyPeerActions.setLobbyPeerPicture(picture, peerId)
              );

              break;
            }

            case 'setAccessCode': {
              const { accessCode } = notification.data;

              store.dispatch(roomActions.setAccessCode(accessCode));

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: 'room.setAccessCode',
                    defaultMessage: 'Access code for room updated.',
                  }),
                })
              );

              break;
            }

            case 'setJoinByAccessCode': {
              const { joinByAccessCode } = notification.data;

              store.dispatch(roomActions.setJoinByAccessCode(joinByAccessCode));

              if (joinByAccessCode) {
                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: 'room.accessCodeOn',
                      defaultMessage: 'Access code for room is now activated.',
                    }),
                  })
                );
              } else {
                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: 'room.accessCodeOff',
                      defaultMessage: 'Access code for room is now deactivated.',
                    }),
                  })
                );
              }

              break;
            }

            case 'activeSpeaker': {
              const { peerId } = notification.data;

              store.dispatch(roomActions.setRoomActiveSpeaker(peerId));

              if (peerId && peerId !== this._peerId)
                this._spotlights.handleActiveSpeaker(peerId);

              break;
            }

            case 'changeDisplayName': {
              const { peerId, displayName, oldDisplayName } = notification.data;

              store.dispatch(peerActions.setPeerDisplayName(displayName, peerId));

              store.dispatch(chatActions.addMessagesWithChangedDisplayName(displayName, peerId));

              store.dispatch(fileActions.addFilesWithChangedDisplayName(displayName, peerId))

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage(
                    {
                      id: 'room.peerChangedDisplayName',
                      defaultMessage: '{oldDisplayName} is now {displayName}.',
                    },
                    {
                      oldDisplayName,
                      displayName,
                    }
                  ),
                })
              );
              const recordingText = store.getState().room.recordingActionText;
              if (!!recordingText) {
                const userName = truncateString(displayName);
                this.handleRecordingActionTextState(`${userName} is recording the call.`, false);
              }
              break;
            }

            case 'changePicture': {
              const { peerId, picture } = notification.data;

              store.dispatch(peerActions.setPeerPicture(peerId, picture));

              break;
            }

            case 'chatMessage': {
              const { peerId, chatMessage } = notification.data;

              store.dispatch(
                chatActions.addResponseMessage({ ...chatMessage, peerId })
              );

              if (
                !store.getState().toolarea.toolAreaOpen ||
                (store.getState().toolarea.toolAreaOpen &&
                  store.getState().toolarea.currentToolTab !== 'chat')
              ) {
                // Make sound
                store.dispatch(roomActions.setToolbarsVisible(true));
                this._soundNotification();
              }

              // scroll to new message
              scrollToMessageId(null, chatMessage?.messageId);

              break;
            }

            case 'sendFile': {
              const { peerId, magnetUri, displayName, fileName, messageId } =
                notification.data;
              store.dispatch(
                fileActions.addFile(peerId, magnetUri, displayName, fileName, messageId)
              );

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: 'room.newFile',
                    defaultMessage: 'New file available.',
                  }),
                })
              );

              if (
                !store.getState().toolarea.toolAreaOpen ||
                (store.getState().toolarea.toolAreaOpen &&
                  store.getState().toolarea.currentToolTab !== 'files')
              ) {
                // Make sound
                store.dispatch(roomActions.setToolbarsVisible(true));
                this._soundNotification();
              }

              break;
            }

            case 'producerScore': {
              const { producerId, score } = notification.data;

              store.dispatch(producerActions.setProducerScore(producerId, score));

              break;
            }

            case 'newPeer': {
              const { id, displayName, picture } = notification.data;

              store.dispatch(
                peerActions.addPeer({ id, displayName, picture, consumers: [] })
              );

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage(
                    {
                      id: 'room.newPeer',
                      defaultMessage: '{displayName} joined the room.',
                    },
                    {
                      displayName,
                    }
                  ),
                })
              );

              break;
            }

            case 'peerClosed': {
              const { peerId } = notification.data;

              store.dispatch(peerActions.removePeer(peerId));

              break;
            }

            case 'consumerClosed': {
              const { consumerId } = notification.data;
              const consumer = this._consumers.get(consumerId);

              const { fullScreenConsumer } = store.getState().room;

              if (!consumer) break;

              if (consumer.appData.source === 'screen') {
                this.setScreenSharedStatus();
              }

              consumer.close();

              if (consumer.hark != null) consumer.hark.stop();

              this._consumers.delete(consumerId);

              const { peerId, source } = consumer.appData;

              if (fullScreenConsumer) {
                if (fullScreenConsumer === consumerId) {
                  if (source === 'screen' || source === 'webcam') {
                    store.dispatch(
                      roomActions.toggleConsumerFullscreen(consumerId)
                    );
                  }
                }
              }

              store.dispatch(consumerActions.removeConsumer(consumerId, peerId));

              break;
            }

            case 'consumerPaused': {
              const { consumerId } = notification.data;
              const consumer = this._consumers.get(consumerId);

              if (!consumer) break;

              store.dispatch(
                consumerActions.setConsumerPaused(consumerId, 'remote')
              );

              break;
            }

            case 'consumerResumed': {
              const { consumerId } = notification.data;
              const consumer = this._consumers.get(consumerId);

              if (!consumer) break;

              store.dispatch(
                consumerActions.setConsumerResumed(consumerId, 'remote')
              );

              break;
            }

            case 'consumerLayersChanged': {
              const { consumerId, spatialLayer, temporalLayer } =
                notification.data;
              const consumer = this._consumers.get(consumerId);

              if (!consumer) break;

              store.dispatch(
                consumerActions.setConsumerCurrentLayers(
                  consumerId,
                  spatialLayer,
                  temporalLayer
                )
              );

              break;
            }

            case 'consumerScore': {
              const { consumerId, score } = notification.data;

              store.dispatch(consumerActions.setConsumerScore(consumerId, score));

              break;
            }

            case 'reconnect': {
              if (!this._isRoomJoined) {
                const isClearSocket = true;
                await this.clearAllResources(isClearSocket);
                const myNewPeerId = randomString({ length: 8 }).toLowerCase();

                // again join the room from
                this.join({
                  roomId: this._roomId,
                  joinVideo: this.joinVideo,
                  peerId: myNewPeerId,
                  profileUrl: this.profileUrl,
                  displayName: this.displayName,
                });
              } else {
                this.restartIce();
              }
              break;
            }

            case 'whiteboardAction': {
              const { actionBy,whiteboardInitiator = '', showWhiteboard, hideWhiteboard } =
                notification.data;
              this._whiteboardInitiator = whiteboardInitiator;

              if (showWhiteboard) {
                this.showWhiteboard({
                  actionBy,
                });
              } else if (hideWhiteboard) {
                this.hidewWhiteboard({
                  actionBy,
                });
              }
              break;
            }

            case 'setLocalRecording': {
              const { peerId, localRecordingState } = notification.data;
              const { me, peers } = store.getState();
              const { recordingOptions } = store.getState().room;
              let displayNameOfRecorder;

              if(recordingOptions){
                store.dispatch(roomActions.setRecordingOptions({ recordingOptions: false }));
              }

              if (peerId === me.id) {
                displayNameOfRecorder = store.getState().settings.displayName;
              } else if (peers[peerId])
                displayNameOfRecorder =
                  store.getState().peers[peerId].displayName;
              else return;

              // Save state to peer
              store.dispatch(
                peerActions.setPeerLocalRecordingState(
                  peerId,
                  localRecordingState
                )
              );

              switch (localRecordingState) {
                case 'start':
                  store.dispatch(
                    requestActions.notify({
                      text: intl.formatMessage(
                        {
                          id: 'room.localRecordingStarted',
                          defaultMessage: '{displayName} started recording.',
                        },
                        {
                          displayName: displayNameOfRecorder,
                        }
                      ),
                    })
                  );
                  const userName = truncateString(displayNameOfRecorder);
                  this.handleRecordingActionTextState(`${userName} is recording the call.`);
                  break;
                case 'resume':
                  store.dispatch(
                    requestActions.notify({
                      text: intl.formatMessage(
                        {
                          id: 'room.localRecordingResumed',
                          defaultMessage: '{displayName} resumed recording.',
                        },
                        {
                          displayName: displayNameOfRecorder,
                        }
                      ),
                    })
                  );
                  break;
                case 'pause': {
                  store.dispatch(
                    requestActions.notify({
                      text: intl.formatMessage(
                        {
                          id: 'room.localRecordingPaused',
                          defaultMessage: '{displayName} paused recording.',
                        },
                        {
                          displayName: displayNameOfRecorder,
                        }
                      ),
                    })
                  );
                  break;
                }
                case 'stop':
                  store.dispatch(roomActions.setRecordingOption({ recordingType: "" }));
                  store.dispatch(
                    requestActions.notify({
                      text: intl.formatMessage(
                        {
                          id: 'room.localRecordingStopped',
                          defaultMessage: '{displayName} stopped recording.',
                        },
                        {
                          displayName: displayNameOfRecorder,
                        }
                      ),
                    })
                  );
                  break;
                default:
                  break;
              }
              break;
            }

            case 'startRecording': {
              const { peerId, localRecordingState } = notification.data;
              const { me, peers } = store.getState();
              const { recordingOptions } = store.getState().room;
              let displayNameOfRecorder;

              if(recordingOptions){
                store.dispatch(roomActions.setRecordingOptions({ recordingOptions: false }));
              }

              if (peerId === me.id) {
                displayNameOfRecorder = store.getState().settings.displayName;
              } else if (peers[peerId])
                displayNameOfRecorder =
                  store.getState().peers[peerId].displayName;
              else return;

              // Save state to peer
              store.dispatch(
                peerActions.setPeerLocalRecordingState(
                  peerId,
                  localRecordingState
                )
              );
              const userName = truncateString(displayNameOfRecorder);
              this.handleRecordingActionTextState(`${userName} is recording the call.`);
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage(
                    {
                      id: 'room.localRecordingStarted',
                      defaultMessage: '{displayName} started recording.',
                    },
                    {
                      displayName: displayNameOfRecorder,
                    }
                  ),
                })
              );
                
              break;
            }

            case 'stopRecording': {
              const { peerId, localRecordingState } = notification.data;
              const { me, peers } = store.getState();
              let displayNameOfRecorder;

              if (peerId === me.id) {
                displayNameOfRecorder = store.getState().settings.displayName;
              } else if (peers[peerId])
                displayNameOfRecorder =
                  store.getState().peers[peerId].displayName;
              else return;

              // Save state to peer
              store.dispatch(
                peerActions.setPeerLocalRecordingState(
                  peerId,
                  localRecordingState
                )
              );
              store.dispatch(roomActions.setRecordingOption({ recordingType: "" }));

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage(
                    {
                      id: 'room.localRecordingStopped',
                      defaultMessage: '{displayName} stopped recording.',
                    },
                    {
                      displayName: displayNameOfRecorder,
                    }
                  ),
                })
              );

              break;
            }

            case 'slowNetwork': {
              const { peerId, ...networkData } = notification.data;

              store.dispatch(peerActions.setPeerSlowNetwork(peerId, networkData));

              break;
            }

            default: {
              logger.error(
                'unknown notification.method "%s"',
                notification.method
              );
            }
          }
        } catch (error) {
          logger.error('error on socket "notification" event failed:"%o"', error);

          store.dispatch(
            requestActions.notify({
              type: 'error',
              text: intl.formatMessage({
                id: 'socket.requestError',
                defaultMessage: 'Error on server request.',
              }),
            })
          );
        }
      });

      this._signalingSocket.on('roomNotStarted', () => {
        store.dispatch(roomActions.setRoomState('new'));
        store.dispatch(roomActions.setIsRoomCreated(true));
      })

    } catch (error) {
      logger.error('join() | failed: %o', error);
      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: 'socket.disconnected',
            defaultMessage: 'Network error, pleas try again later.',
          }),
        })
      );
      this.close('network_error');
    }
  }

  async sessionDestroyEnd() {
    await this.sendRequest('sessionDestroyEnd');
  }

  async _joinRoom({ joinVideo }) {
    logger.debug('_joinRoom()');

    // set false to connecting new room
    this._isRoomJoined = false;
    const { displayName, picture, email } = store.getState().settings;


let isWebCamProduced = false,isScreenProduced = false; 

    try {

      this._mediasoupDevice = new mediasoupClient.Device();

      const routerRtpCapabilities = await this.sendRequest(
        'getRouterRtpCapabilities'
      );

      await this._mediasoupDevice.load({ routerRtpCapabilities });

      if (this._produce) {
        // creating send transport
        await this.initializeSendTransport();
      }

      // creating receive transport
      await this.initializeReceiverTransport();

      // Set our media capabilities.
      store.dispatch(
        meActions.setMediaCapabilities({
          canSendMic: this._mediasoupDevice.canProduce('audio'),
          canSendWebcam: this._mediasoupDevice.canProduce('video'),
          canShareScreen:
            this._mediasoupDevice.canProduce('video') &&
            this._screenSharing.isScreenShareAvailable(),
          canShareFiles: true //this._torrentSupport,
        })
      );

      const { peers, authenticated, isSessionCountingStarted, whiteboardInitator, isWhiteboardOpen, callTimer } = await this.sendRequest('join', {
        displayName: displayName,
        email: email,
        picture: picture,
        rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
      });
      
      this._callStartTime = callTimer;
      store.dispatch(roomActions.setCallTimer(callTimer));

      // Set whiteboardInitiator PeerId
      this._whiteboardInitiator = whiteboardInitator;

      store.dispatch(meActions.loggedIn(authenticated));

      logger.debug('_joinRoom() joined, got peers [peers:"%o"]', peers);

      for (const peer of peers) {
        store.dispatch(peerActions.addPeer({ ...peer, consumers: [] }));
      }

      this._spotlights.addPeers(peers);

      this._spotlights.on('spotlights-updated', (spotlights) => {
        store.dispatch(roomActions.setSpotlights(spotlights));
        this.updateSpotlights(spotlights);
      });

      // Don't produce if explicitly requested to not to do it.
      if (this._produce) {
        if (this._mediasoupDevice.canProduce('audio'))
         // if (!this._muted) this.enableMic();

        if (joinVideo && this._mediasoupDevice.canProduce('video'))
          this.enableWebcam();

        // Producer mic/video/screen if user re-join after reconnecting and already have produce anything.
        if(isWebCamProduced){
          this.enableWebcam();
        }

        if(isScreenProduced){
          this.enableScreenSharing();
        }
      }

      store.dispatch(roomActions.setRoomState('connected'));

      // Clean all the existing notifications.
      store.dispatch(notificationActions.removeAllNotifications());

      this.getServerHistory();

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: 'room.joined',
            defaultMessage: 'You have joined the room.',
          }),
        })
      );      

      // Check for whiteboad 
      const roomInfo = store.getState().room;
      const isWhiteboard = roomInfo.isWhiteboardOpen;
      
      if(!!isWhiteboardOpen !== !!isWhiteboard){
        isWhiteboardOpen
        ? store.dispatch(roomActions.setShowWhiteboard())
        : store.dispatch(roomActions.setHideWhiteboard());
      }

      if(isSessionCountingStarted !== null) {
        store.dispatch(roomActions.setDestroyTimer(isSessionCountingStarted ));
      }
      this._spotlights.start();

      // set true to connecting new room successfully.
      setTimeout(() => {
        this._isRoomJoined = true;
      }, 2500);
    } catch (error) {
      logger.error('_joinRoom() failed:"%o"', error);
      
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'room.cantJoin',
            defaultMessage: 'Unable to join the room.',
          }),
        })
      );

      this.close('_joinRoom');
    }
  }

  async lockRoom(userName) {
    logger.debug('lockRoom()');

    try {
      store.dispatch(roomActions.setRoomLocked());
      await this.sendRequest('lockRoom', {
        displayName: userName,
      });

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: 'room.youLocked',
            defaultMessage: 'You locked the room.',
          }),
        })
      );
    } catch (error) {
      store.dispatch(roomActions.setRoomUnLocked());
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'room.cantLock',
            defaultMessage: 'Unable to lock the room.',
          }),
        })
      );

      logger.error('lockRoom() | failed: %o', error);
    }
  }

  async unlockRoom(userName) {
    logger.debug('unlockRoom()');

    try {
      store.dispatch(roomActions.setRoomUnLocked());
      await this.sendRequest('unlockRoom', {
        displayName: userName,
      });

      // store.dispatch(roomActions.setRoomUnLocked());

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: 'room.youUnLocked',
            defaultMessage: 'You unlocked the room.',
          }),
        })
      );
    } catch (error) {
      store.dispatch(roomActions.setRoomLocked());
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'room.cantUnLock',
            defaultMessage: 'Unable to unlock the room.',
          }),
        })
      );

      logger.error('unlockRoom() | failed: %o', error);
    }
  }

  async setAccessCode(code) {
    logger.debug('setAccessCode()');

    try {
      await this.sendRequest('setAccessCode', { accessCode: code });

      store.dispatch(roomActions.setAccessCode(code));

      store.dispatch(
        requestActions.notify({
          text: 'Access code saved.',
        })
      );
    } catch (error) {
      logger.error('setAccessCode() | failed: %o', error);
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: 'Unable to set access code.',
        })
      );
    }
  }

  async setJoinByAccessCode(value) {
    logger.debug('setJoinByAccessCode()');

    try {
      await this.sendRequest('setJoinByAccessCode', {
        joinByAccessCode: value,
      });

      store.dispatch(roomActions.setJoinByAccessCode(value));

      store.dispatch(
        requestActions.notify({
          text: `You switched Join by access-code to ${value}`,
        })
      );
    } catch (error) {
      logger.error('setAccessCode() | failed: %o', error);
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: 'Unable to set join by access code.',
        })
      );
    }
  }

  async enableMic() {
    let track;
    try {
      if (this._micProducer) return;
      if (this._mediasoupDevice && !this._mediasoupDevice.canProduce('audio')) {
        logger.error('enableMic() | cannot produce audio');

        return;
      }

      store.dispatch(meActions.setAudioInProgress(true));

      const deviceId = await this._getAudioDeviceId();
      const webCamId = await this._getWebcamDeviceId();

      const device = this._audioDevices[deviceId];
      logger.debug('enableMic() | deviceId', deviceId);
      logger.debug('enableMic() | webCamId', webCamId);
      if (!device) throw new Error('no audio devices');

      logger.debug(
        'enableMic() | new selected audio device [device:%o]',
        device
      );

      logger.debug('enableMic() | calling getUserMedia()');
       
      const {
				autoGainControl,
				echoCancellation,
				noiseSuppression,
				sampleRate,
				channelCount,
				sampleSize,
			} = store.getState().settings;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio : {
          deviceId : { ideal: deviceId },
          sampleRate,
          channelCount,
          autoGainControl,
          echoCancellation,
          noiseSuppression,
          sampleSize
        }
      });

      track = stream.getAudioTracks()[0];

      this._micProducer = await this._sendTransport.produce({
        track,
        codecOptions: {
          opusStereo: 1,
          opusDtx: 1,
        },
        appData: { source: 'mic' },
      });

      store.dispatch(
        producerActions.addProducer({
          id: this._micProducer.id,
          source: 'mic',
          paused: this._micProducer.paused,
          track: this._micProducer.track,
          rtpParameters: this._micProducer.rtpParameters,
          codec:
            this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1],
        })
      );

      store.dispatch(settingsActions.setSelectedAudioDevice(deviceId));
      store.dispatch(settingsActions.setSelectedWebcamDevice(webCamId));

      await this._updateAudioDevices();

      this._micProducer.on('transportclose', () => {
        this._micProducer = null;
      });

      this._micProducer.on('trackended', () => {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.microphoneDisconnected',
              defaultMessage: 'Microphone disconnected.',
            }),
          })
        );

        this.disableMic().catch(() => {});
      });

      this._micProducer.volume = 0;

      const harkStream = new MediaStream();

      harkStream.addTrack(track);

      if (!harkStream.getAudioTracks()[0])
        throw new Error('enableMic(): given stream has no audio track');

      if (this._hark != null) this._hark.stop();

      this._hark = hark(harkStream, { play: false });

      // eslint-disable-next-line no-unused-vars
      this._hark.on('volume_change', (dBs, threshold) => {
        // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
        //   Math.pow(10, dBs / 20)
        // However it does not produce a visually useful output, so let exaggerate
        // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
        // minimize component renderings.
        let volume = Math.round(Math.pow(10, dBs / 85) * 10);

        if (volume === 1) volume = 0;

        volume = Math.round(volume);

        if (this._micProducer && volume !== this._micProducer.volume) {
          this._micProducer.volume = volume;

          store.dispatch(peerVolumeActions.setPeerVolume(this._peerId, volume));
        }
      });
    } catch (error) {
      const permissionDenied = 'NotAllowedError';
      const noMicrophoneFound = 'no audio devices';
      
      logger.error('enableMic() failed:%o', error);

      if (error.name === permissionDenied) {
        let permissionDeniedMessage = '';
        if (this._device.platform === "mobile" && this._device.os === "ios") {
          permissionDeniedMessage = 'Permission denied for microphone. Please enable microphone access in your browser settings and rejoin the room.';
        } else {
          permissionDeniedMessage = 'Permission denied for microphone.';
        }
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.microphoneError',
              defaultMessage: permissionDeniedMessage,
            }),
          })
        );
      } else if (error.message === noMicrophoneFound) {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.microphoneError',
              defaultMessage: 'Microphone not available.',
            }),
          })
        );
      } else {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.microphoneError',
              defaultMessage:
                'An error occurred while accessing your microphone.',
            }),
          })
        );
      }

      if (track) track.stop();
    }

    store.dispatch(meActions.setAudioInProgress(false));
  }

  async disableMic() {
    logger.debug('disableMic()');

    if (!this._micProducer) return;

    store.dispatch(meActions.setAudioInProgress(true));

    try {
      this._micProducer.close();

      store.dispatch(producerActions.removeProducer(this._micProducer.id));
      await this.sendRequest('closeProducer', {
        producerId: this._micProducer.id,
      });
    } catch (error) {
      logger.error('disableMic() [error:"%o"]', error);

    }

    this._micProducer = null;

    store.dispatch(meActions.setAudioInProgress(false));
  }

  async enableScreenSharing() {
    if (this._screenSharingProducer) return;

    if (!this._mediasoupDevice.canProduce('video')) {
      logger.error('enableScreenSharing() | cannot produce video');

      return;
    }

    let track;

    store.dispatch(meActions.setScreenShareInProgress(true));

    try {
      const available = this._screenSharing.isScreenShareAvailable();

      if (!available) throw new Error('screen sharing not available');

      logger.debug('enableScreenSharing() | calling getUserMedia()');

      const stream = await this._screenSharing.start({
        width: 1920,
        height: 1080,
        frameRate: FRAME_RATE_SCREEN_SHARE,
      });

      track = stream.getVideoTracks()[0];
      
      const { isScreenShared } = store.getState().me;
      if (isScreenShared) throw new Error('screen sharing is in use');

      if (this._useSharingSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec =
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.kind === 'video'
          );

        let encodings;

        if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
          encodings = VIDEO_SVC_ENCODINGS;
        } else {
          if ('simulcastEncodings' in window.config) {
            encodings = window.config.simulcastEncodings.map((encoding) => ({
              ...encoding,
              dtx: true,
            }));
          } else {
            encodings = VIDEO_SIMULCAST_ENCODINGS.map((encoding) => ({
              ...encoding,
              dtx: true,
            }));
          }
        }
        
        this._screenSharingProducer = await this._sendTransport.produce({
          track,
          encodings,
          codecOptions: {
            videoGoogleStartBitrate: 1000,
          },
          appData: {
            source: 'screen',
            frameRate: stream.getVideoTracks()[0].getSettings().frameRate
          },
        });
      } else {
        this._screenSharingProducer = await this._sendTransport.produce({
          track,
          appData: {
            source: 'screen',
            frameRate: stream.getVideoTracks()[0].getSettings().frameRate
          },
        });
      }

      store.dispatch(
        producerActions.addProducer({
          id: this._screenSharingProducer.id,
          deviceLabel: 'screen',
          source: 'screen',
          paused: this._screenSharingProducer.paused,
          track: this._screenSharingProducer.track,
          rtpParameters: this._screenSharingProducer.rtpParameters,
          codec:
            this._screenSharingProducer.rtpParameters.codecs[0].mimeType.split(
              '/'
            )[1],
        })
      );

      this._screenSharingProducer.on('transportclose', () => {
        
        this._screenSharingProducer = null;
      });

      this._screenSharingProducer.on('trackended', () => {
        // store.dispatch(
        //   requestActions.notify({
        //     type: 'error',
        //     text: intl.formatMessage({
        //       id: 'devices.screenSharingDisconnected',
        //       defaultMessage: 'Screen sharing disconnected.',
        //     }),
        //   })
        // );

        this.disableScreenSharing().catch(() => {});
      });

      logger.debug('enableScreenSharing() succeeded');
    } catch (error) {
      logger.error('enableScreenSharing() failed: %o', error);
      if(error.message === "Permission denied by system") {
        store.dispatch(meActions.setScreenShareErrorMessage(error.message));
      }
     

      if (track) track.stop();
    }

    store.dispatch(meActions.setScreenShareInProgress(false));
  }

  async disableScreenSharing() {
    logger.debug('disableScreenSharing()');

    if (!this._screenSharingProducer) return;

    store.dispatch(meActions.setScreenShareInProgress(true));

    this._screenSharingProducer.close();

    store.dispatch(
      producerActions.removeProducer(this._screenSharingProducer.id)
    );

    try {
      await this.sendRequest('closeProducer', {
        producerId: this._screenSharingProducer.id,
      });
    } catch (error) {
      logger.error('disableScreenSharing() [error:"%o"]', error);
      
    }

    this._screenSharingProducer = null;

    store.dispatch(meActions.setScreenShareInProgress(false));
    this.resetShareSreenErrorMessage();
  }

  getMicTrack() {
    let micTrack = null;
    if (this._micProducer) {
      micTrack = this._micProducer.track;
    }
    return micTrack;
  }

  async enableWebcam() {
    if (this._webcamProducer) return;
    let track;

    store.dispatch(meActions.setWebcamInProgress(true));

    const noWebCamFound = 'no webcam devices';
    const permissionDenied = 'NotAllowedError';
    try {
      if (!this._mediasoupDevice.canProduce('video')) {
        logger.error('enableWebcam() | cannot produce video');
  
        return;
      }
      const lastSelectedMode = store.getState().settings.facingMode;
      const deviceId = await this._getWebcamDeviceId();

      const device = this._webcams[deviceId];
      const resolution = store.getState().settings.resolution;
      logger.debug('enableWebcam() | device', device);
      if (!device) throw new Error(noWebCamFound);

      logger.debug(
        '_setWebcamProducer() | new selected webcam [device:%o]',
        device
      );

      logger.debug('_setWebcamProducer() | calling getUserMedia()');
      let constraints = {
        video: {
          deviceId: { ideal: deviceId },
          ...VIDEO_CONSTRAINS[resolution],
        },
      }
      if (this._device.platform === 'mobile') {
        constraints = {
          video: { facingMode: { exact: lastSelectedMode }}
        }
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
  
      track = stream.getVideoTracks()[0];

      if (this._useSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec =
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.kind === 'video'
          );

        let encodings;

        if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9')
          encodings = VIDEO_KSVC_ENCODINGS;
        else {
          if ('simulcastEncodings' in window.config)
            encodings = window.config.simulcastEncodings;
          else encodings = VIDEO_SIMULCAST_ENCODINGS;
        }

        this._webcamProducer = await this._sendTransport.produce({
          track,
          encodings,
          codecOptions: {
            videoGoogleStartBitrate: 1000,
          },
          appData: {
            source: 'webcam',
            frameRate: stream.getVideoTracks()[0].getSettings().frameRate,
            platform: this._device.os || ''
          },
        });
      } else {
        this._webcamProducer = await this._sendTransport.produce({
          track,
          appData: {
            source: 'webcam',
            frameRate: stream.getVideoTracks()[0].getSettings().frameRate,
            platform: this._device.os || ''
          },
        });
      }

      store.dispatch(
        producerActions.addProducer({
          id: this._webcamProducer.id,
          deviceLabel: device.label,
          source: 'webcam',
          paused: this._webcamProducer.paused,
          track: this._webcamProducer.track,
          rtpParameters: this._webcamProducer.rtpParameters,
          codec:
            this._webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1],
        })
      );

      store.dispatch(settingsActions.setSelectedWebcamDevice(deviceId));

      await this._updateWebcams();

      this._webcamProducer.on('transportclose', () => {
        this._webcamProducer = null;
      });

      this._webcamProducer.on('trackended', () => {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.cameraDisconnected',
              defaultMessage: 'Camera disconnected.',
            }),
          })
        );

        this.disableWebcam().catch(() => {});
      });

      logger.debug('_setWebcamProducer() succeeded');
    } catch (error) {
      logger.error('_setWebcamProducer() failed:%o', error);
      

      if (error.name === permissionDenied) {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.cameraError',
              defaultMessage: 'Permission denied for camera.',
            }),
          })
        );
      } else if (error.message === noWebCamFound) {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.cameraError',
              defaultMessage: 'Camera not available.',
            }),
          })
        );
      } else {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: intl.formatMessage({
              id: 'devices.cameraError',
              defaultMessage: 'An error occurred while accessing your camera.',
            }),
          })
        );
      }
      if (track) track.stop();
    }

    store.dispatch(meActions.setWebcamInProgress(false));
  }

  async disableWebcam() {
    logger.debug('disableWebcam()');

    if (!this._webcamProducer) return;

    store.dispatch(meActions.setWebcamInProgress(true));

    try {
      this._webcamProducer.close();

      store.dispatch(producerActions.removeProducer(this._webcamProducer.id));
      await this.sendRequest('closeProducer', {
        producerId: this._webcamProducer.id,
      });
    } catch (error) {
      logger.error('disableWebcam() [error:"%o"]', error);
      
    }

    this._webcamProducer = null;

    store.dispatch(meActions.setWebcamInProgress(false));
  }

  async _updateAudioDevices() {
    logger.debug('_updateAudioDevices()');

    // Reset the list.
    this._audioDevices = {};

    try {
      logger.debug('_updateAudioDevices() | calling enumerateDevices()');

      const devices = await navigator.mediaDevices.enumerateDevices();
       
      for (const device of devices) {
        if (device.kind !== 'audioinput') continue;
        this._audioDevices[device.deviceId] = device;
      }

      store.dispatch(meActions.setAudioDevices(this._audioDevices));
    } catch (error) {
      logger.error('_updateAudioDevices() failed:%o', error);
      
    }
  }

  async _updateWebcams() {
    logger.debug('_updateWebcams()');

    // Reset the list.
    this._webcams = {};

    try {
      logger.debug('_updateWebcams() | calling enumerateDevices()');

      const devices = await navigator.mediaDevices.enumerateDevices();
       
      for (const device of devices) {
        if (device.kind !== 'videoinput') continue;
        this._webcams[device.deviceId] = device;
      }

      store.dispatch(meActions.setWebcamDevices(this._webcams));
    } catch (error) {
      logger.error('_updateWebcams() failed:%o', error);
      
    }
  }

  async _getAudioDeviceId() {
    logger.debug('_getAudioDeviceId()');

    try {
      logger.debug('_getAudioDeviceId() | calling _updateAudioDeviceId()');

      await this._updateAudioDevices();

      const { selectedAudioDevice } = store.getState().settings;

      if (selectedAudioDevice && this._audioDevices[selectedAudioDevice])
        return selectedAudioDevice;
      else {
        const audioDevices = Object.values(this._audioDevices);

        return audioDevices[0] ? audioDevices[0].deviceId : null;
      }
    } catch (error) {
      logger.error('_getAudioDeviceId() failed:%o', error);
      
    }
  }

  async _getWebcamDeviceId() {
    logger.debug('_getWebcamDeviceId()');

    try {
      logger.debug('_getWebcamDeviceId() | calling _updateWebcams()');

      await this._updateWebcams();

      const { selectedWebcam } = store.getState().settings;

      if (selectedWebcam && this._webcams[selectedWebcam])
        return selectedWebcam;
      else {
        const webcams = Object.values(this._webcams);

        return webcams[0] ? webcams[0].deviceId : null;
      }
    } catch (error) {
      logger.error('_getWebcamDeviceId() failed:%o', error);
      
    }
  }

  async initializeSendTransport() {
    try {
      store.dispatch(roomActions.setIceCandidateState(''));

      const transportInfo = await this.sendRequest('createWebRtcTransport', {
        forceTcp: this._forceTcp,
        producing: true,
        consuming: false,
      });
      
      const { id, iceParameters, iceCandidates, dtlsParameters } =
        transportInfo;

      this._sendTransport = this._mediasoupDevice.createSendTransport({
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        iceServers: ROOM_OPTIONS.turnServers,
        proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
      });

      
      this._sendTransport.on(
        'connect',
        (
          { dtlsParameters },
          callback,
          errback // eslint-disable-line no-shadow
        ) => {
          this.sendRequest('connectWebRtcTransport', {
            transportId: this._sendTransport.id,
            dtlsParameters,
          })
            .then(callback)
            .catch(errback);
        }
      );

      this._sendTransport.on('connectionstatechange', (connectState) => {

      if (this._closed) return;

        switch (connectState) {
          case 'disconnected':
          case 'failed':
                    
        store.dispatch(roomActions.setIceCandidateState('error'));

        logger.error('_sendTransport connectionstatechange', connectState);
            this.restartTransportIce(
              this._sendTransport,
              this._sendRestartIce,
              2000
            );
            break;

          default:
            store.dispatch(roomActions.setIceCandidateState(''));
            clearTimeout(this._sendRestartIce.timer);
            break;
        }
      });

      this._sendTransport.on(
        'produce',
        async ({ kind, rtpParameters, appData }, callback, errback) => {
          try {
            // eslint-disable-next-line no-shadow
            const { id } = await this.sendRequest('produce', {
              transportId: this._sendTransport.id,
              kind,
              rtpParameters,
              appData,
            });

            callback({ id });
          } catch (error) {
            logger.error('this._sendTransport() | failed: %o', error);
            errback(error);
          }
        }
      );

      this.monitorNetworkQuality(this._sendTransport, "SEND");
    
    } catch (error) {
      logger.error('initializeSendTransport() | failed: %o', error);
    } finally {
      return this._sendTransport;
    }
  }

  async initializeReceiverTransport() {
    try {
      
      store.dispatch(roomActions.setIceCandidateState(''));

      const transportInfo = await this.sendRequest('createWebRtcTransport', {
        forceTcp: this._forceTcp,
        producing: false,
        consuming: true,
      });

      
      const { id, iceParameters, iceCandidates, dtlsParameters } =
        transportInfo;
      this._recvTransport = this._mediasoupDevice.createRecvTransport({
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        iceServers: ROOM_OPTIONS.turnServers,
      });
      
      this._recvTransport.on(
        'connect',
        (
          { dtlsParameters },
          callback,
          errback // eslint-disable-line no-shadow
        ) => {
          this.sendRequest('connectWebRtcTransport', {
            transportId: this._recvTransport.id,
            dtlsParameters,
          })
            .then(callback)
            .catch(errback);
        }
      );

      this._recvTransport.on('connectionstatechange', (connectState) => {
       
        if (this._closed) return;
        
        switch (connectState) {
          case 'disconnected':
          case 'failed':
             
        store.dispatch(roomActions.setIceCandidateState('error'));

        logger.error('_recvTransport connectionstatechange', connectState);

            this.restartTransportIce(
              this._recvTransport,
              this._recvRestartIce,
              2000
            );
            break;

          default:
            store.dispatch(roomActions.setIceCandidateState(''));
            clearTimeout(this._recvRestartIce.timer);
            break;
        }
      });

      this.monitorNetworkQuality(this._recvTransport, "RECEIVE");
      
    } catch (error) {
      logger.error('initializeReceiverTransport() | failed: %o', error);
    } finally {
      return this._recvTransport;
    }
  }

  async clearAllResources(isClearSocket) {
    try {
      if (this._signalingSocket && isClearSocket) {
        this._signalingSocket.emit('request', { method: 'leave' });
        this._signalingSocket.close();
      }

      if (this._mediasoupDevice) {
        this._mediasoupDevice = null;
      }

      // Close mediasoup Transports.
      if (this._sendTransport) {
        await this._sendTransport.close();
        this._sendTransport = null;
      }

      if (this._recvTransport) {
        await this._recvTransport.close();
        this._recvTransport = null;
      }

      if (this._micProducer && !this._micProducer.closed) {
        await this._micProducer.close();
        this._micProducer = null;
      }

      if (this._webcamProducer && !this._webcamProducer.closed) {
        await this._webcamProducer.close();
        this._webcamProducer = null;
      }

      if (this._hark) {
        await this._hark?.stop();
        this._hark = null;
      }

      if (this._screenSharingProducer) {
        await this._screenSharingProducer.close();
        this._screenSharingProducer = null;
      }
    } catch (error) {
      logger.error('clearAllResources() | failed: %o', error);
    } finally {
      return;
    }
  }

  async showWhiteboard(info) {
    logger.debug('showWhiteboard()', info);

    try {
      store.dispatch(roomActions.setShowWhiteboard());
      if (info?.actionBy) {
        this.whiteboardToaster(`${info?.actionBy} launched the whiteboard.`);
      }
    } catch (error) {
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'room.cantOpenWhiteboard',
            defaultMessage: 'Unable to open the whiteboard.',
          }),
        })
      );

      logger.error('showWhiteboard() | failed: %o', error);
      
    }
  }

  async whiteboardNotification(payload) {
    await this.sendNotification('whiteboardAction', payload);
  }

  async whiteboardToaster(msg) {
    if (msg) {
      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: 'room.whiteboard',
            defaultMessage: msg,
          }),
        })
      );
    }
  }

  async setWhiteboardInitiator(value) {
    try {
      store.dispatch(roomActions.setWhiteboardInitiator(value));
    } catch (error) {
      logger.error('setWhiteboardInitiator() | failed: %o', error);
    }
  }

  async hidewWhiteboard(info) {
    logger.debug('hideWhiteboard()');

    try {
      store.dispatch(roomActions.setHideWhiteboard());

      if (info?.actionBy) {
        this.whiteboardToaster(`${info?.actionBy} closed the whiteboard.`);
      }
    } catch (error) {
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: intl.formatMessage({
            id: 'room.cantHideWhiteboard',
            defaultMessage: 'Unable to Hide the whiteboard.',
          }),
        })
      );

      logger.error('hideWhiteboard() | failed: %o', error);
    }
  }


  async closeConsumer(payload) {
    try {
    const { consumerId } = payload;
    const consumer = this._consumers.get(consumerId);
    if (!consumer) {
        return;
    }
    consumer.close();
    if (consumer.hark != null) consumer.hark.stop();
    this._consumers.delete(consumerId);
    const { peerId } = consumer.appData;
    store.dispatch(consumerActions.removeConsumer(consumerId, peerId));
    return;
  } catch (error) {
    logger.error('closeConsumer() | failed: %o', error);
  }
  }

  async resumeRemoteConsumer(payload) {
    try {
  
    const { consumerId } = payload;
    const consumer = this._consumers.get(consumerId);
    if (!consumer) {
        return;
    }
    store.dispatch(consumerActions.setConsumerResumed(consumerId, 'remote'));
    return;
        
  } catch (error) {
    logger.error('resumeRemoteConsumer() | failed: %o', error);
  }
  }

  async pauseRemoteConsumer(payload) {
    try {

    const { consumerId } = payload;
    const consumer = this._consumers.get(consumerId);
    if (!consumer) {
        return;
    }
    store.dispatch(consumerActions.setConsumerPaused(consumerId, 'remote'));
    return;
          
  } catch (error) {
    logger.error('pauseRemoteConsumer() | failed: %o', error);
  }
  }

  onlineTrigger(){
    
    this.onlineHandler();
  }
  offlineTrigger(){
    
    this.offlineHandler();
  }

  async offlineHandler(){
    try {
  
    if(this._timeout) return;
    this._timeout = setTimeout(() => {
      
      const {status} = store.getState().recorder.localRecordingState;
      if(status === 'start'){
        recorder.stopLocalRecording("stoppedByDisconnectEvent");
      }
      this.close('disconnect_timeout');
    }, RECONNECTING_TIME);

    if (!this._isRoomJoined) {
      const isClearSocket = false;
      this.clearAllResources(isClearSocket);
    }

    if(this._isRoomJoined){
    store.dispatch( 
      requestActions.notify({
        text: intl.formatMessage({
          id: 'socket.reconnecting',
          defaultMessage: 'You are disconnected, attempting to reconnect.',
        }),
      })
    );

    store.dispatch(roomActions.setRoomState('connecting'));
  }
      
} catch (error) {
  logger.error('offlineHandler() | failed: %o', error);
}
  }

  async onlineHandler(){
    try {
    
    if (this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
     
      const { state } = store.getState().room;
      if(state !== 'connecting'){
        const rejoinInfo = await this.sendRequest('rejoin');
        this.updateConsumer(rejoinInfo);
      }
    }
      
  } catch (error) {
    logger.error('onlineHandler() | failed: %o', error);
  }
  }

  async stopCloudRecording(callFrom){
    store.dispatch(roomActions.setRecordingOption({ recordingType: "" }));
    
    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: 'room.youStoppedLocalRecording',
          defaultMessage: 'You stopped recording.',
        }),
      })
    );

    store.dispatch(recorderActions.setLocalRecordingState('stop'));
    if(callFrom !== 'BROWSER_BACK') {
    await this.sendRequest('stopRecording', {
      localRecordingState: 'stop',
      email: store.getState().settings.email
    });
  }

    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: 'room.youStoppedLocalRecording',
          defaultMessage: 'The recorded file would be converted to mp4 and share over your email.',
        }),
      })
    );
  }

  async startCloudRecording(){
    store.dispatch(recorderActions.setLocalRecordingState("start"));
    await this.sendRequest("startRecording", {
      localRecordingState: "start",
      email: store.getState().settings.email
    });
    this._soundNotification(ALERT_SOUND_TYPE.RECORD_IN_PROGRESS);
    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: "room.youStartedLocalRecording",
          defaultMessage: "You started recording.",
        }),
      })
    );
  }
  
  resetShareSreenErrorMessage() {
    store.dispatch(meActions.setScreenShareErrorMessage(null));
  }
  
  handleRecordingActionTextState(text = "", playSound = true) {
    store.dispatch(
      roomActions.setRecordingActionText({
        recordingActionText: text,
      })
    );
    if (playSound) {
      const alertSoundType = !!text
        ? ALERT_SOUND_TYPE.RECORD_STARTED
        : ALERT_SOUND_TYPE.RECORD_IN_STOPPED;
      this._soundNotification(alertSoundType);
    }
  }

  setScreenSharedStatus (status = false) {
    store.dispatch(meActions.setIsScreenShared(status));
  }

}
