import { ALLOWED_IMAGES, API_HOST } from "./constants";
import getDeviceInfo from "../deviceInfo";
import { store } from "../store";
import * as settingsActions from "../actions/settingsActions";
import { googleLogout } from '@react-oauth/google';
import axios from 'axios';

// const axios = require("axios").default;
const SIGNAL_CONST = 7;

const axiosInstance = axios.create({
  baseURL: API_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setLogs = async (payload) => {
  try {
    const response = await axios.post(
      `${API_HOST}room/logger`,
      { reqParam: payload },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data && response.data.status === "success") {
      return response;
    } else {
      throw new Error(`${response.data.message || 'Unknown error'}`);
    }
  } catch (error) {
    return error;
  }
};

export const raiseAnIssue = async (payload) => {
  try {
    const response = await axios.post(
      `${API_HOST}users/reportBug`,
      { reqParam: payload },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 201) {
      return {
        isSuccess: true,
      };
    } else {
      return {
        isError: true,
      };
    }
  } catch (error) {
    return {
      isError: true,
      error: error,
    };
  }
};

export const noop = () => { };

export const fileTobase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (error) => reject(error);
  });

export const downloadImage = (ImageBase64) => {
  const { os, platform } = getDeviceInfo();
  const isIOS = platform === "mobile" && os === "ios";
  if (isIOS) {
    // For iOS device, open the image in a new tab
    const newTab = window.open(ImageBase64, "_blank");
    if (newTab) {
      newTab.document.write(`
      <html>
      <head>
        <title>Image</title>
      </head>
      <body style="text-align: center; padding: 20px;">
        <div style="margin-top: 150px; background-image: url('/images/bg-whiteboard-ios.png'); background-size: cover; background-position: center; height: 900px; width: 100%; position: relative;">
          <div>
            <img src="${ImageBase64}" alt="Image" style="max-width: 100%; height: 100%; object-fit: contain; object-position: center;">
          </div>
        </div>

        <div style="margin-top: 40px;">
          <a href="${ImageBase64}" download="Image.png">
            <button style="padding: 48px; background-color: #026EDD; font-size: 34px; font-weight: 600; color: white; border: none; border-radius: 50px; cursor: pointer;">
              Download Image
            </button>
          </a>
        </div>
      </body>
    </html>
    `);
      newTab.document.close();
    }
  } else {
    let a = document.createElement("a");
    a.href = ImageBase64;
    a.download = "Image.png";
    a.click();
  }
  return;
};

export const calculatePercentageValue = (num, per) => {
  return (num / 100) * per;
};

export const validationProfileImage = (file, fileSize, allowedImage) => {
  const megaByte = file?.size / (1024 * 1024);
  const [extension] = file?.name.split(".").reverse();
  if (megaByte > fileSize) {
    return {
      sizeError: true,
    };
  }
  if (!allowedImage.includes(extension.toLowerCase())) {
    return {
      typeError: true,
    };
  }
  return {
    isOK: true,
  };
};

export const imageAcceptTypes = () => {
  var pre = "image/";
  return (pre + ALLOWED_IMAGES.join(";" + pre)).split(";").toString();
};

export const converFileNameForView = (name, chars, subst) => {
  if (name) {

    return name.replace(
      new RegExp("^(.{0," + (35 - subst.length) + "})[^\\.]+"),
      "$1" + subst
    );
  } else {
    return name;
  }
};

export const scrollToMessageId = (customElement, messageId) => {
  const element = customElement?.current || document?.body;
  return element?.querySelectorAll(
    `[messageid='${messageId}']`
  )?.[0];
};

// export const getSignalStrength = (packetLossPercentage) => {
//   if (!packetLossPercentage) {
//     return 100;
//   }
//   if (packetLossPercentage > SIGNAL_CONST) {
//     packetLossPercentage = SIGNAL_CONST;
//   }
//   return Math.round(((SIGNAL_CONST - packetLossPercentage) / SIGNAL_CONST) * 100)
// };

export const checkMicStatus = (intl, me, micProducer) => {
  let micState;

  let micTip;

  if (!me.canSendMic) {
    micState = "unsupported";
    micTip = intl.formatMessage({
      id: "device.audioUnsupported",
      defaultMessage: "Audio unsupported",
    });
  } else if (!micProducer) {
    micState = "off";
    micTip = intl.formatMessage({
      id: "device.activateAudio",
      defaultMessage: "Activate audio",
    });
  } else if (!micProducer.locallyPaused && !micProducer.remotelyPaused) {
    micState = "on";
    micTip = intl.formatMessage({
      id: "device.muteAudio",
      defaultMessage: "Mute audio",
    });
  } else {
    micState = "muted";
    micTip = intl.formatMessage({
      id: "device.unMuteAudio",
      defaultMessage: "Unmute audio",
    });
  }
  return {
    micState,
    micTip,
  };
};

export const checkVideoStatus = (intl, me, webcamProducer) => {
  let webcamState;

  let webcamTip;

  if (!me.canSendWebcam) {
    webcamState = "unsupported";
    webcamTip = intl.formatMessage({
      id: "device.videoUnsupported",
      defaultMessage: "Video unsupported",
    });
  } else if (webcamProducer) {
    webcamState = "on";
    webcamTip = intl.formatMessage({
      id: "device.stopVideo",
      defaultMessage: "Stop video",
    });
  } else {
    webcamState = "off";
    webcamTip = intl.formatMessage({
      id: "device.startVideo",
      defaultMessage: "Start video",
    });
  }

  return { webcamState, webcamTip };
};

export const verifyRecordingPasscode = async (payload) => {
  try {
    const response = await axios.post(
      `${API_HOST}recording/fetch`,
      { reqParam: payload },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return {
        isSuccess: true,
        data: response.data.response,
      };
    }
  } catch (error) {
    return {
      isError: true,
      error: error.response.data,
    };
  }
};

export const generateRandomString = () => {
  return Math.random().toString(36).substring(2, 10);
}

export const generateDisplayName = () => {
  const min = 100;
  const max = 999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
  return `Guest-${randomNumber}`;
}

export const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export const enableCloudRecording = () => {
  return (
    process.env.REACT_APP_SERVER_URL ===
    "https://msdev.galaxysofttech.co.in/backend/" ||
    process.env.REACT_APP_SERVER_URL === "https://stg-pro.talkroom.io/backend/" ||
    process.env.REACT_APP_SERVER_URL === "https://localhost:8445/"
  );
};

export const truncateString = (str = "", maxLength = 25) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }
  return str;
};

export const getIPInfo = async () => {
  try {
      const response = await fetch('https://ipapi.co/json/');
      if (!response.ok) {
          throw new Error('Failed to fetch IP address');
      }
      const data = await response.json();
      const {
        ip: ipAddress = "",
        city = "",
        region_code: state = "",
        country_name: country = "",
      } = data || {};
      return { ipAddress, city, state, country };
  } catch (error) {
      return null;
  }
};

export const generateExtraData = () => {
  const { name: browser, os, platform, version } = getDeviceInfo();
  return {
      browser,
      os,
      platform,
      version,
  };
};

// Generic method for making Axios requests
export const makeRequest = async (method, url, data = null, token = null) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };

    // Include Authorization header if a token is provided
    if (token) {
      headers['Authorization'] = token;
    }

    const response = await axiosInstance({
      method,
      url,
      data:  { reqParam: data },
      headers,
    });

    if (response.data && (response.status === 201 || response.status === 200)) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Unknown error');
    }
  } catch (error) {
    return handleApiError(error);
  }
};

// Response interceptors
axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
if(error?.response?.status === 403){
  window.location.href = '/';
  logOutHandler();
}
  return Promise.reject(error);
});

const handleApiError = (error) => {
  return error.response?.data || {
    isError: true,
    message: error.message || "Internal server error",
    statusCode: null
  };
};

export const verifyEmail = async (verificationToken) => {
    const response = await axios.get(`${API_HOST}users/verify-email?verificationToken=${verificationToken}`);
    return response.data;
};

export const logOutHandler = () => {
  const dispatch = store.dispatch;
  dispatch(settingsActions.setUserId(null));
  dispatch(settingsActions.setUserData(null));
  dispatch(settingsActions.invalidAttempts(0));
  dispatch(settingsActions.fetchIPInfo(null));
  googleLogout();
};
