import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  homeParent:{
    '& img':{
      maxWidth:'100%'
    }
  },
  headerGrid: {
    padding: "4px 26px",
    width: "100%",
    height: "65px",
    borderBottom: `1px solid ${theme.common.gray90}`,
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0",
      height: "52px",
    },  
  },
  bannerHeading: {
    maxWidth:920,
    margin:'0 auto',
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 44,
      marginBottom: "20px",
    },
    [theme.breakpoints.down("550")]: {
      fontSize: "24px",
     lineHeight:'40px'
    },
  },
  howItWorksHeading: {
    marginBottom: 70,
    color: theme.common.white,
    textAlign:'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "24px",
      marginBottom: "34px",
    },
  },
 
  conversationHeading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      lineHeight: "32px",
    },
  },
  conversationSubHeading: {
    marginTop: theme.spacing(1.5),
    color:theme.palette.black.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "20px",
    },
  },
  mgoMobileImage: {
    maxWidth: "fit-content",
    position: "relative",
    borderRadius: 12,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  tryTalkroom: {
    background: theme.common.grayishBlue,
    minHeight:733
  },
 
  mobileImageOverlay: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "45%",
      backgroundImage: `linear-gradient(to bottom, rgba(255,0,0,0), ${theme.palette.white.main})`,
    },
  },
  inputBorder: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 52,
      border: `1px solid ${theme.common.lightGrayBorder}`,
      borderRadius: "44px",
      background:theme.common.lightGrey,
      marginBottom:16,
      [theme.breakpoints.up("sm")]:{
        height: 76,
        marginBottom:0
      },
      '& .MuiOutlinedInput-input':{
        fontSize:18,
      }
    },
    [theme.breakpoints.down("450")]: {
      margin: "auto",
    },
  },
  footerBg: {
    backgroundColor: theme.palette.black.extraLightSm,
    padding: "1px 0px 2px",
    '& p':{
      color:theme.common.white
    },
    '& a':{
      color:theme.common.white
    },
    '& span':{
      color:theme.common.white
    }
  },
  outlineBtn:{
    borderColor:theme.palette.black.light,
    color:theme.palette.black.light,
    '& svg':{
      fontSize:"30px !important"
    }
  },
  joinBtnLink:{
    minHeight:52,
    background:theme.palette.primary.main,
    color:theme.common.white,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:17,
    fontWeight:500,
    borderRadius:50,
    padding:theme.spacing(0.75, 2)
  },
  activityParentBox:{
    background:theme.common.grayLight50,
    position:'absolute',
    bottom:40,
    width:'100%'
  },
    // Dialog Styles
    dialogPaper: {
      maxWidth: "60%",
      backgroundColor: theme.common.white,
      borderRadius: 20,
      position: 'relative',
      [theme.breakpoints.down("sm")]: {
        maxWidth: "80%",
      },
    },
    dtitle:{
        padding:0,
        border:"none"
    },
    howTouseTopCloseIcon:{
      position:"absolute !important",
      cursor:"pointer",
      padding:"6px !important", 
      minWidth:"32px"
    },
    dialogContent: {
      padding:" 0 48px 48px",
      color: theme.palette.black.main,
      textAlign: 'center',
      '& video': {
        width: '100%',
        height: 'auto',
      },
      [theme.breakpoints.down('sm')]: {
        padding:" 8px 24px",  
        },
    },
    howToUseHeading: {
      backgroundColor: theme.palette.primary.dark,
      padding: "10px",
  },
  closeIcon: {
    right: "43px",
    minHeight: "30px",
   "&>.MuiIconButton-label":{
   color : theme.common.black,

   },
    "@media (max-width:360px)": {
      top: "8px",
    },
  },
  grayCircleBtn: {
    border: "none",
    backgroundColor: theme.common.white,
    minWidth: "37px",
    "&:hover": {
           opacity: ".8",
           backgroundColor: theme.common.white,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-5px",
      right: "-5px",
      color: theme.common.white,
    },
  },
  topHeadingBg:{
    position:"relative",
    fontSize:"26px",
    fontWeight: 700,
    backgroundColor:theme.common.white, 
    color:"#010101",
    width:"100%",  
    borderRadius: "20px 20px 0 0",
    [theme.breakpoints.down('sm')]: {
      fontSize:"16px",
  },
  },
  howToUseBtn: {
      cursor: "pointer",
  },
}));

export default useStyles;
