import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  lobbyMsg: {
    color:theme.palette.success.main,
    fontWeight: 'bold',
    [theme.breakpoints.down("450")]: {
      width: "200px",
      margin: "auto",
    },
    [theme.breakpoints.down("1200")]: {
      width: "300px",
      margin: "auto",
    },
  },
  inputBorder: {
    width: "100%",
    minHeight:105,
    "& .MuiOutlinedInput-root": {
      height: 52,
      border: `1px solid ${theme.common.lightGrayBorder}`,
      borderRadius: "44px",
      background:theme.common.lightGrey,
      [theme.breakpoints.up("sm")]:{
        height: 76,
        marginBottom:0
      },
      '& .MuiOutlinedInput-input':{
        fontSize:18,
        fontFamily:theme.typography.fontFamily.medium,
        fontWeight:theme.typography.fontWeight.medium,
        [theme.breakpoints.down("450")]: {
          fontSize:14,
        },
      }
    },
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      minHeight:'auto',
    },
  },
  
}));

export default useStyles;
