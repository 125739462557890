import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography
} from "@material-ui/core";
import useStyles from "./RegisterStyles";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { makeRequest ,generateExtraData, generateRandomString} from "../../../utils/utils";
import { API_ENDPOINT, METHOD_TYPES, RE_CAPTCHA_SECRET_KEY } from "../../../utils/constants";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as settingsActions from "../../../actions/settingsActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from '../../../utils/validation';
import { Alert } from "@material-ui/lab";
import useDebounce from "../../../Hooks/useDebounce";
import { useHistory, Link } from "react-router-dom";
import GoogleAuth from "../SocialLogin/GoogleAuthentication/GoogleAuth";
// import FacebookAuth from "../SocialLogin/FacebookAuthentication/FacebookAuth";
import IconButton from '@material-ui/core/IconButton';
import withLoader from "../../../components/hoc/withLoader";
import { sendAnalyticsEvent, GTM_EVENT, GTM_METHODS } from "../../../utils/gtmUtils";

const Register = ({setLoadWithoutMount, handleClickOpenPopup , handleClickClosePopup}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const ipInfo = useSelector(state => state.settings.ipInfo);
  const roomId = useSelector(state => state?.settings?.userData?.roomId) || generateRandomString();
  const [captchaChecked, setCaptchaChecked] = useState(false);  
  const [captchaMessage, setCaptchaMessage] = useState(false);  
  const [passwordType, setPasswordType] = useState("password");
  // const [isLoading, setIsLoading] = useState(false);
  const [passwordRepeatType, setPasswordRepeatType] = useState('password');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [emailError, setEmailError] = useState(null);
  const scrollRef = useRef(null);
  // Edge 20+
// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;

  const { register, handleSubmit, watch, setValue, formState: { errors, isDirty, isValid } } = useForm({
    resolver: yupResolver(registerSchema),
    mode: "onTouched",
  });

  const watchTerms = watch("terms", false);
  const email = watch("email", "");
  const debouncedValue = useDebounce(email, 500);
  const togglePassword = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const togglePasswordRepeat = () => {
    setPasswordRepeatType(
      passwordRepeatType === "password" ? "text" : "password"
    );
  };

  useEffect(() => {
    const checkAvailability = async () => {
      try {
        if (errors.email) {
          return false;
        }
        const response = await makeRequest(
          METHOD_TYPES.POST,
          API_ENDPOINT.CHECK_EMAIL_AVAILIBILITY,
          {email}
        );
        setEmailError(response.message);
      } catch (error) {
        setEmailError(error);
      }
    };

    if (debouncedValue) {
      checkAvailability();
    } else {
      setEmailError("");
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  const onSubmit = async (formData) => {
    if (!captchaChecked) {
      setCaptchaMessage(true);
      return;
    }
    // setIsLoading(true);
    setLoadWithoutMount(true)
    try {
      const systemInfo = generateExtraData();

      const payload = {
        ...formData,
        extraData: {
          ipInfo,
          systemInfo,
        },
      };
      const response = await makeRequest(
        METHOD_TYPES.POST,
        API_ENDPOINT.REGISTER,
        payload
      );
      // setIsLoading(false);
      setLoadWithoutMount(false)
      if (response.statusCode === 201) {
        sendAnalyticsEvent(GTM_EVENT.CREATE_ACCOUNT, GTM_METHODS.BUTTON, GTM_METHODS.CLICK, 'Manual')
        setSnackbarMessage({ message: response.message, severity: "success" });
        setCaptchaChecked(false);
        setCaptchaMessage(false);
        document.activeElement.blur();
        Object.keys(formData).forEach((key) => {
          if(key === 'terms'){
            setValue(key, false);
          }else{
            setValue(key, "");
          }
        });
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: 'smooth',  block: "center",
          inline: "start" });
        }
      } else {
        setSnackbarMessage({ message: response.message, severity: "error" });
      }
    } catch (error) {
      // setIsLoading(false);
      setLoadWithoutMount(false)
    }
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaChecked(true);
    } else {
      setCaptchaChecked(false);
    }
  };

  const handleCaptchaExpired = () => {
    setCaptchaChecked(false);
    setCaptchaMessage(true);
  };

  // const handleFacebookSignUp = useCallback(async (response) => {
  //   const userData = {
  //     firstName: response.name.split(" ")[0],
  //     lastName: response.name.split(" ")[1] || "",
  //     email: response.email
  //   };
  //   await handleAuthentication("facebook", userData);
  // }, []);

  const handleAuthentication = useCallback(async (provider, userData) => {
    try {
      const systemInfo = generateExtraData();
      const extraData = {
        ipInfo,
        systemInfo,
        isGuest: false,
      };
      const payload = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        registrationMethod: provider,
        extraData
      };

      const result = await makeRequest(
        METHOD_TYPES.POST,
        API_ENDPOINT.SOCAIL_AUTH,
        payload
      );
      if (result.statusCode === 200) {
        const { userId, firstName, lastName = "", email, birthYear, token, avatar, registrationMethod } = result.data || {};
        dispatch(settingsActions.setUserId(userId));
        dispatch(settingsActions.setUserData({
          firstName,
          lastName,
          email,
          birthYear,
          token,
          avatar,
          roomId,
          isGuest: false,
          registrationMethod
        }));
        // history.push(`/${roomId}`);
        handleClickClosePopup()
        history.push(`/`); // change dashboard 
      } else {
        const { userId = null, birthYear = null } = result.data || {};
        setSnackbarMessage({ message: result.message, severity: 'error' });
        if (!birthYear && !!userId) {
          dispatch(settingsActions.setUserId(userId));
          history.push('/birth-year');
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
}, [dispatch, roomId, history, ipInfo]);

  const handleGoogleSignUp = useCallback(async (response) => {
    sendAnalyticsEvent(GTM_EVENT.CREATE_ACCOUNT, GTM_METHODS.BUTTON, GTM_METHODS.CLICK, 'Google')
    const userData = {
      firstName: response.given_name,
      lastName: response.family_name,
      email: response.email
    };
    await handleAuthentication("google", userData);
  }, [handleAuthentication]);

  return (
    <>
          <Box ref={scrollRef}>
              {!!snackbarMessage && (
                <Box mb={2} className="animate-box">
                  <Alert className={classes.iconBtn}  severity={snackbarMessage.severity} onClose={() => setSnackbarMessage(null)}>
                    {snackbarMessage.message}
                  </Alert>
                </Box>
              )}
          </Box> 
              <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container >
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <Box mb={2} position={'relative'}>
                  <FormGroup>
                    <TextField
                      id="firstName"
                      placeholder="First Name *"
                      className={classes.inputBorder}
                      variant="outlined"
                      name="firstName"
                      type="text"
                      {...register("firstName")}
                      error={!!errors.firstName}
                      inputProps={{ maxLength: 64 }}
                    />
                  </FormGroup>
                  {errors.firstName && (
                    <p className={classes.error}>{errors.firstName.message}</p>
                  )}
                </Box>
               </Grid>
                <Grid item lg={12}  md={12} xs={12} sm={12}>
                  <Box mb={2} position={'relative'}>
                  <FormGroup>
                    <TextField
                      id="lastName"
                      placeholder="Last Name"
                      className={classes.inputBorder}
                      variant="outlined"
                      name="lastName"
                      {...register("lastName")} 
                      error={!!errors.lastName}
                      inputProps={{ maxLength: 64 }}
                    />
                  </FormGroup>
                  {errors.lastName && (
                    <p className={classes.error}>{errors.lastName.message}</p>
                  )}
                  </Box>
                   </Grid>
                  <Grid item lg={12}  md={12} xs={12} sm={12}>
                <Box mb={2} position={'relative'}>
                  <FormGroup>
                    <TextField
                      id="email"
                      placeholder="Enter Email *"
                      className={classes.inputBorder}
                      variant="outlined"
                      name="email"
                      type="email"
                      {...register("email")} 
                      error={!!errors.email}
                    />
                  </FormGroup>
                  {errors.email && <p className={classes.error}>{errors.email.message}</p>}
                  {!!!errors.email && !!emailError && <p className={classes.error}>{emailError}</p>}
                </Box>
                </Grid>
                <Grid item lg={12}  md={12} xs={12} sm={12}>
                <Box mb={2} position={'relative'}>
                  <FormGroup>
                    <TextField
                      id="birthYear"
                      placeholder="Year of Birth *"
                      className={`${classes.inputBorder} ${classes.inputNumber}`}
                      variant="outlined"
                      name="birthYear"
                      type="number"
                      onInput={(e)=>{ 
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                      }}
                      min={5}
                      {...register("birthYear")}
                      error={!!errors.birthYear}
                    />
                  </FormGroup>
                  {errors.birthYear && <p className={classes.error}>{errors.birthYear.message}</p>}
                </Box>
                </Grid>
                <Grid item lg={12}  md={12} xs={12} sm={12}>
                <Box mb={2}>
                  <FormGroup>
                    <TextField
                      id="password"
                      placeholder="Enter Password *"
                      className={`${classes.inputBorder} ${classes.adornmentIcon}`}
                      variant="outlined"
                      name="password"
                      type={passwordType}
                      {...register("password")} 
                      error={!!errors.password}
                     
                      InputProps={{
                        inputProps:{maxLength: 21},
                        endAdornment: (
                          !!!isEdge && (
                                <IconButton onClick={togglePassword} edge="end"
                                 >
                                    {passwordType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                          )
                        )
                      }}
                    />                     
                  </FormGroup>
                  {errors.password && <p className={classes.error}>{errors.password.message}</p>}
                </Box>
                </Grid>
                <Grid item lg={12}  md={12} xs={12} sm={12}>
                <Box mb={2}>
                  <FormGroup>
                    <TextField
                      id="password_repeat"
                      placeholder="Confirm Password *" 
                      className={`${classes.inputBorder} ${classes.adornmentIcon}`}
                      type={passwordRepeatType} 
                      variant="outlined"
                      name="password_repeat"
                      {...register("password_repeat")} 
                      error={!!errors.password_repeat || (errors.password && errors.password.type === 'oneOf')}
                      
                      InputProps={{
                        inputProps:{maxLength: 21},
                        endAdornment: (
                          !!!isEdge && (
                                <IconButton onClick={togglePasswordRepeat} edge="end"
                                 >
                                    {passwordRepeatType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                          )
                        )
                      }}
                    />
                  </FormGroup>
                  {errors.password_repeat && <p className={classes.error}>{errors.password_repeat.message}</p>}
                  {errors.password && errors.password.type === 'oneOf' && <p className={classes.error}>{errors.password.message}</p>}
                </Box>
                </Grid>
                <Grid item lg={12}  md={12} xs={12} sm={12}>
                <Box mb={3}  className={classes.checkBox} alignItems="center">
                  <FormGroup>
                    <FormControlLabel
                    className={`${classes.label} ${classes.checkBoxLabel}`}
                      control={
                        <Checkbox
                          id="terms"
                          {...register("terms")}
                          checked={watchTerms}
                        />
                      }
                    />
                  <Box className={classes.label}>I agree to the <Link to="/terms-of-use" target="_blank" className={classes.link}>Terms & Conditions</Link> and <Link to="/privacy-policy" target="_blank" className={classes.link}>Privacy Policy</Link></Box>
                  </FormGroup>
                  {errors.terms && <p className={classes.error}>{errors.terms.message}</p>}
                </Box>
                </Grid>
               <Grid item lg={12}  md={12} xs={12} sm={12}>
                <Box mb={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column" className={classes.responsiveCaptcha}>
                  <FormGroup>
                    <ReCAPTCHA
                      sitekey={RE_CAPTCHA_SECRET_KEY}
                      onChange={handleCaptchaChange}
                      onExpired={handleCaptchaExpired}
                    />
                  </FormGroup>
                    {captchaMessage && !captchaChecked && (
                      <p className={classes.error}>Please verify the captcha.</p>
                    )}
                </Box>
                </Grid>
                </Grid>
                <Box mb={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.registerBtn}
                  disabled={ !isDirty || !isValid || !!emailError}
                  >
                  Create Account
                </Button>
                  </Box>
                </form>
                <Box mb={3} textAlign="center">
                 <button type="button" className={classes.textBtn}  onClick={() => handleClickOpenPopup('login', 'Sign in')}>
                  Have an account?
                  </button>
                  </Box>
                <Box mb={3} width="100%">
                <Box  mb={3} display="flex" alignItems="center" justifyContent="space-between" width="100%">

                <Divider style={{flex: '1'}} />

                <Typography style={{ marginInline: 10 }}>OR</Typography>
                <Divider style={{flex: '1'}} />
                </Box>
                <Box  display="flex" alignItems="center" justifyContent="space-evenly" width="100%">
                <GoogleAuth btnText="Continue with Google" onSuccess={handleGoogleSignUp} />
                {/* <FacebookAuth onSuccess={handleFacebookSignUp} /> */}
              </Box>
            </Box>
    </>
  );
};

export default withLoader(Register);
